import { Box, styled } from "@mui/material";
// Sections
import TopNavbar from "../../Nav/TopNavbar";
import Services from "./Services";
import Pricing from "./Pricing";
import Contact from "./Contact";
import Footer from "./Footer";
import UpcForm from "./UpcForm";
import ProductList from "./ProductList";
import ProductData from "./ProductData";

const LandingPage = () => (
  <StyledBox>
    <TopNavbar />
    <UpcForm />
    <ProductList />
    <Services />
    <ProductData />
    <Pricing />
    <Contact />
    <Footer />
    {/* <Projects /> */}
    {/* <Blog /> */}
  </StyledBox>
);

export default LandingPage;

const StyledBox = styled(Box)(({ theme }) => ({
  // fontFamily: "Khula, sans-serif",
  "& p:not(:has(em))": {
    marginBottom: 0,
    color: "rgba(0, 0, 0, 0.8)",
  },
  "& .container": {
    margin: "0 auto",
    width: "100%",
    padding: "0 1.875rem",
    maxWidth: "1220px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "1040px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "860px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "680px",
    },
  },
}));
