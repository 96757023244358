import Helmet from "react-helmet";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// modules/components
import PublicRoute from "./routes/public";
import AuthRoute from "./routes/auth";
import PagesRoute from "./routes/pages";
import PageNotFound from "./components/pages/PageNotFound";
import UserPrivateRoute from "./routes/userPrivate";
import UserRoutes from "./routes/users";
import Layout from "./components/Dashboard/Layout";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/index.css";

const App = () => {
  return (
    <>
      {/* prettier-ignore */}
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
        {/* <!-- Calendly badge widget begin --> */}
        <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
        <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async />
        {/* <!-- Calendly badge widget end --> */}
      </Helmet>
      <ToastContainer autoClose={10000} />
      <BrowserRouter>
        <Routes>
          {/* Authentication Routes */}
          <Route path="auth" element={<PublicRoute />}>
            <Route path="*" element={<AuthRoute />} />
            <Route path="" element={<PageNotFound />} />
          </Route>

          {/* Pages Routes */}
          <Route path="*" /* element={<UserRoute />} */>
            <Route path="*" element={<PagesRoute />} />
          </Route>

          {/* User Dashboard Routes */}
          <Route
            path="dashboard"
            element={<UserPrivateRoute withLayout={Layout} />}
          >
            <Route path="*" element={<UserRoutes />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
