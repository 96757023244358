import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// MUI
import { Box, Paper, Popper, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles, styled } from "@mui/styles";
// Assets/Modules

const useStyles = makeStyles((theme) => ({
  tableMargin: {
    "& > div": {
      marginLeft: 10,
    },
  },
  popperMargin: {
    "& .MuiPopper-root": {
      marginLeft: "10px",
      padding: "10px",
    },
  },
  footer: {
    "& .MuiTablePagination > p": {
      marginBottom: 0,
    },
  },
}));

const MuiDataGrid = ({
  tableHeight,
  checkbox,
  rowSelectionClick = true,
  getSelectedRows,
  filterModel,
  getFilterModel,
  ...data
}) => {
  const classes = useStyles();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [filterModelObject, setFilterModelObject] = useState({
    items: [{ field: "", operator: "contains", value: "" }],
  });

  // prettier-ignore

  // useEffect(() => {
  //   if (filterModel && filterModelObject.items[0] == undefined) {
  //     setFilterModelObject(filterModel);
  //   }
  //   if ( page || page == 0) {
  //     setPaginationModel({ ...paginationModel, page });
  //   }
  //   if (data.selectedRows) setRowSelectionModel(data.selectedRows);
  // }, [filterModel, filterModelObject, page, data.selectedRows]);

  // // Selection Row Id
  // const handleSelectionModelChange = (ids) => {
  //   getSelectedRows && getSelectedRows(ids);
  //   setRowSelectionModel(ids);
  // };

  // Filteration
  // const handleFilterModelChange = (newFilterModel) => {
  //   const items = newFilterModel.items[0];
  //   items && getFilterModel(paginationModel, newFilterModel);
  //   setFilterModelObject(newFilterModel);
  //   setRowSelectionModel([]);
  // };

  // Pagination
  // const handlePaginationModelChange = (newPaginationModel) => {
  //   getPaginationModel(newPaginationModel, filterModelObject);
  //   // setPaginationModel(newPaginationModel);
  //   setRowSelectionModel([]);
  // };

  // // Editing
  // // state management not yet implemented/needed accordingly
  // const handleProcessRowUpdate = useCallback((updatedRow) => {
  //   processRowUpdate(updatedRow);
  // }, []);

  return (
    <Box sx={{ height: tableHeight, width: "100%" }}>
      <DataGrid
        {...data}
        className={`${classes.tableMargin}`}
        autoHeight={false}
        height="100%"
        checkboxSelection={checkbox}
        disableRowSelectionOnClick={rowSelectionClick}
        rowSelectionModel={rowSelectionModel}
        // onRowSelectionModelChange={handleSelectionModelChange}
        // // FILTERATION
        // filterMode="server"
        // filterModel={filterModelObject}
        // onFilterModelChange={handleFilterModelChange}
        // EDITING
        // processRowUpdate={handleProcessRowUpdate}
        // onProcessRowUpdateError={handleProcessRowUpdateError}
      />
    </Box>
  );
};

export default MuiDataGrid;

/**********  Expand/Popup Cell Content On hover  **********/
const GridCellExpand = memo((props) => {
  const { width, value, classes } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const isOverflown = (element) => {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  };

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Box className={`${classes.popperMargin}`}>
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width /*,  marginLeft: -17 */ }}
            // modifiers={{
            //   offset: {
            //     enabled: true,
            //     offset: '0, 10px' // add 10 pixels to the bottom
            //   },
            //   preventOverflow: {
            //     padding: 10 // add 10 pixels of padding around the popper
            //   },
            //   flip: {
            //     padding: 10 // add 10 pixels of padding around the popper
            //   }
            // }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        </Box>
      )}
    </Box>
  );
});

// exported to columns in adminPanel
export const RenderCellExpand = (params) => {
  const classes = useStyles();
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
      classes={classes}
    />
  );
};

export const CustomToolbar = () => {
  return (
    <StyledGridToolbar>
      <GridToolbarColumnsButton />
      {/* <GridToolbarFilterButton /> */}
      <GridToolbarDensitySelector />
      {/* <GridToolbarExport /> */}
    </StyledGridToolbar>
  );
};

const StyledGridToolbar = styled(GridToolbarContainer)(({ theme }) => ({
  marginTop: 10,
}));
