import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal, Box } from "@mui/material";
import { STRIPE_PUBLIC_KEY } from "../../utils/constants";
import CheckoutForm from "../Elements/Checkout";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const options = {
  payment_method_types: ["card"],
  mode: "payment",
  amount: 1099,
  currency: "usd",
  fields: {
    email: true,
  },
};

const PaymentModal = (props) => {
  // type is for change payment method only
  const { open, onClose, email, type } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="payment-modal">
        <div className="flexCenter flexColumn">
          {type ? <h2>Change Payment Method</h2> : <h2>Payment Checkout</h2>}
          {stripePromise && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm onClose={onClose} email={email} type={type} />
            </Elements>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default PaymentModal;
