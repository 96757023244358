import { Route, Routes } from "react-router-dom";
// Assets/Modules
import Subscription from "../components/Dashboard/Subscription";
import PageNotFound from "../components/pages/PageNotFound";
import Invoice from "../components/Dashboard/Invoice";
import PaymentMethod from "../components/Dashboard/Payments";
import Preview from "../components/Dashboard/Invoice/preview";
import ResetPassword from "../components/pages/Auth/ResetPassword";

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="*">
        <Route path="subscription" element={<Subscription />} />
        <Route path="invoice">
          <Route path="" element={<Invoice />} />
          <Route path="preview/:id" element={<Preview />} />
        </Route>
        <Route path="payment-method" element={<PaymentMethod />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default UserRoutes;
