import * as React from "react";
// MUI
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ResponsiveDialog = (props) => {
  const { open, onClose, onConfirm } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    onClose(false);
  };

  const handleConfirmation = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target.form);
    const message = formData.get("feedback");
    onConfirm(message);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          We&apos;re sorry to see you leave &#x1f614;
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            id="feedback-cancel-subscription-form"
            component="form"
            // noValidate
            // autoComplete="off"
          >
            <TextField
              id="filled-basic"
              label="Feedback"
              name="feedback"
              variant="filled"
              size="small"
              minRows={3}
              margin="dense"
              multiline
              fullWidth
            />
          </Box>
          <DialogContentText className="py-2">
            Are you sure you want to cancel the subscription? If so, the action
            is non-refundable and the subscription will expire at the end of
            current subscription period.
          </DialogContentText>
          <Divider />
          <DialogContentText className="py-2">
            Or you may continue using our services!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            form="feedback-cancel-subscription-form"
            size="small"
            type="submit"
            onClick={handleConfirmation}
          >
            Proceed
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClose}
            autoFocus
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResponsiveDialog;
