import React from "react";
import { useNavigate } from "react-router-dom";
// MUI
import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import FullButton from "../CustomStyled/Buttons/FullButton";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">
              The page you&apos;re looking for doesn&apos;t exist.
            </Typography>
            <FullButton
              title="Go Back"
              style={{ maxWidth: "130px" }}
              className="font17 semiBold p-7"
              action={() => navigate(-1)}
            />
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <img
              src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
              alt=""
              width={500}
              height={250}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
};

export default PageNotFound;
