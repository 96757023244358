import amex from "../assets/paymentIcons/amex.png";
import diners from "../assets/paymentIcons/diners.png";
import discover from "../assets/paymentIcons/discover.png";
import jcb from "../assets/paymentIcons/jcb.png";
import mastercard from "../assets/paymentIcons/mastercard.png";
import unionpay from "../assets/paymentIcons/unionpay.png";
import visa from "../assets/paymentIcons/visa.png";
import card from "../assets/paymentIcons/card.png";
import invalidCard from "../assets/paymentIcons/invalid-card.png";

const getPaymentIcon = (cardNumberObject) => {
  const { brand, error } = cardNumberObject;

  if (error) {
    return invalidCard;
  }

  switch (brand) {
    case "amex":
      return amex;
    case "diners":
      return diners;
    case "discover":
      return discover;
    case "jcb":
      return jcb;
    case "mastercard":
      return mastercard;
    case "unionpay":
      return unionpay;
    case "visa":
      return visa;
    default:
      return card;
  }
};

const clearLocalStorageKey = (key) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.removeItem(key);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export { getPaymentIcon, clearLocalStorageKey };
