import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import logger from "redux-logger";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import thunk from "redux-thunk";

import authReducer from "../reducers/auth";
import catalogReducer from "../reducers/catalog";

// variable/states/fields you want to whitelist/blacklist
const authError = createBlacklistFilter("auth", ["error", "status", "message", "done", "loading"]);

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["catalog"],
  transforms: [authError],
};

const reducer = combineReducers({
  auth: authReducer,
  catalog: catalogReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk/* , logger */],
});

export const persistor = persistStore(Store);
export default Store;
