import styled from "styled-components";

const Button = (props) => {
  const { content, ...rest } = props;
  return <StyledButton {...rest}>{content}</StyledButton>;
};

export default Button;

const StyledButton = styled.button`
  background: linear-gradient(to right, #6c19a5 22%, #03217b 78%);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  width: 65%;
  height: 3rem;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
`;
