import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
// Assets/Modules
import { ResendCode } from "../../../redux/reducers/auth";
import Button from "../../CustomStyled/Buttons/ButtonSignIn";
import Input from "../../CustomStyled/Inputs/InputSignIn";
import OverlayLoading from "../../CustomStyled/OverlayLoading";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { err, emailSent, message, loading, status } = useSelector(
    (state) => state.auth
  );

  const [stateObj, setStateObj] = useState({ email: "" });

  useEffect(() => {
    if (message === "Email Sent!" || status === 200) {
      setTimeout(() => {
        navigate("/auth/sign-in?type=forget-password");
      }, 2000);
    }
  }, [message]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setStateObj({ ...stateObj, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("email");
    if (email) {
      dispatch(ResendCode({ email, type: "forget-password" }));
    } else {
      toast.error("Please enter a valid email address");
    }
  };

  return (
    <div className="lightBg p-80">
      {loading && <OverlayLoading />}
      <Wrapper>
        <MainContainer>
          <Title>Forgot Password</Title>
          <small style={styles.resetPassword}>
            Enter your email below to reset your password.
          </small>
          <form
            noValidate
            className="flexNullCenter flexColumn"
            // autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Input
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSubmit(e);
              }}
              style={{ margin: "0 4rem 1.5rem" }}
              type="email"
              name="email"
              placeholder="Email"
              value={stateObj.email}
              onChange={handleInputChange}
            />
            {err || emailSent ? (
              <small style={err ? styles.textDanger : styles.textGreen}>
                {err || message}
              </small>
            ) : (
              ""
            )}
            <Button type="submit" disabled={loading} content="Send" />
          </form>
          <Link to={"/auth/sign-in"}>Sign in </Link>
        </MainContainer>
      </Wrapper>
    </div>
  );
};

export default ForgetPassword;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  @media only screen and (max-width: 768px) {
    height: 96vh;
  }
  @media only screen and (min-width: 2560px) {
    height: 90vh;
  }
`;
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 55vh;
  // -webkit-backdrop-filter: blur(8.5px);
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  & > * {
    margin: 0.625rem 0;
  }
  @media only screen and (max-width: 320px) {
    height: 90vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    height: 90vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 425px) {
    height: 90vh;
  }
  @media only screen and (min-width: 1024px) {
    width: 60%;
    height: 80vh;
    border: 3px solid #755abe;
  }
  @media only screen and (min-width: 1280px) {
    width: 50%;
    height: 60vh;
    border: 3px solid #755abe;
  }
  @media only screen and (min-width: 2560px) {
    width: 30vw;
    height: 40vh;
    border: 3px solid #755abe;
  }
`;
const Title = styled.h1`
  padding: 10px 0px 0px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  text-align: center;
  text-wrap: nowrap;
  color: #292589;
  @media only screen and (max-width: 425px) {
    font-size: 1.5rem;
  }
`;

const styles = {
  grid: {
    height: "100vh",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  pt128: {
    marginBottom: "40px",
    justifyContent: "center",
    width: "250px",
  },
  mt16: {
    marginBottom: "16px",
  },
  pt67: {
    marginTop: "64.59",
    marginBottom: "67px",
    color: "#000000",
    fontSize: "36px",
    textAlign: "center",
    display: "block",
  },
  resetPassword: {
    marginBottom: "23px",
    color: "#201F1E",
    fontSize: "14px",
    textAlign: "center",
    display: "block",
  },
  textRight: {
    marginTop: "16px",
    display: "block",
    color: "#201F1E",
    textAlign: "right",
    fontSize: 14,
  },
  textDanger: {
    color: "#ff0000",
    marginBottom: "0.5rem",
  },
  textGreen: {
    color: "#008000",
    marginBottom: "0.5rem",
  },
};
