import Slider from "react-slick";
import styled from "styled-components";
import { FaAmazon, FaEbay, FaShopify } from "react-icons/fa";
import Icons from "../CustomStyled/Icons";
// SVG
import Walmart from "../../assets/svg/Walmart";
import HomeDepot from "../../assets/svg/HomeDepot";
import Etsy from "../../assets/svg/Etsy";
import WooCommerce from "../../assets/svg/WooCommerce";

const ClientSlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <Icons>
            <FaAmazon />
          </Icons>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <Icons>
            <Walmart />
          </Icons>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <Icons>
            <FaEbay />
          </Icons>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <Icons>
            <HomeDepot />
          </Icons>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <Icons>
            <Etsy />
          </Icons>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <Icons>
            <FaShopify />
          </Icons>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <Icons>
            <WooCommerce />
          </Icons>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <Icons>
            <HomeDepot />
          </Icons>
        </LogoWrapper>
      </Slider>
    </div>
  );
};

export default ClientSlider;

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  div {
    color: grey;
    width: 15.5rem;
    height: 6.5rem;
    svg {
      width: 6.5rem !important;
      height: 3.5rem !important;
      filter: grayScale(1);
    }
  }
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;
