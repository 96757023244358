import { toast } from "react-toastify";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import axios from "../../config/axios";
import { PAGINATION } from "../../utils/constants";

export const getASINData = createAsyncThunk(
  "catalog/get",
  async (data, thunkAPI) => {
    try {
      const { token } = thunkAPI.getState().auth;
      const body = { ...data, token };
      const response = await axios.post("scraping/get-web-identifiers", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return thunkAPI.rejectWithValue({
        error: "Network Error During Catalog Identifier Processing",
      });
    }
  }
);
export const createCustomer = createAsyncThunk(
  "catalog/payments/create-customer",
  async (data, thunkAPI) => {
    try {
      const { token } = thunkAPI.getState().auth;
      const response = await axios.post("/payments/create-customer", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return thunkAPI.rejectWithValue({
        error: "Network Error During Create Customer",
      });
    }
  }
);
export const getSubscription = createAsyncThunk(
  "catalog/payments/get-subscription",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { filters, pagination } = state.catalog;
      const { token } = state.auth;
      const { pageNumber, pageSize } = pagination || {};
      const params = {
        filters,
      };
      const response = await axios.get("payments/get-subscription", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        toast.error(err.response.data.error);
        return rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return rejectWithValue({
        error: "Network Error During Get Subscription Details",
      });
    }
  }
);
export const createSubscription = createAsyncThunk(
  "catalog/payments/create-subscription",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const response = await axios.post("/payments/create-subscription", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        return rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return rejectWithValue({
        error: "Network Error During Create Subscription",
      });
    }
  }
);
export const deleteSubscription = createAsyncThunk(
  "catalog/payments/delete-subscription",
  async (data, thunkAPI) => {
    try {
      const { token } = thunkAPI.getState().auth;
      const response = await axios.delete("/payments/delete-subscription", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        toast.error(err.response.data.error);
        // toast.error(err.response.data.error);
        return thunkAPI.rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return thunkAPI.rejectWithValue({
        error: "Network Error Delete Subscription",
      });
    }
  }
);
export const getInvoices = createAsyncThunk(
  "catalog/payments/get-invoices",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { token } = state.auth;
      const { filters } = state.catalog;
      const params = { filters };
      const response = await axios.get(`payments/get-invoices/${data?.id}`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        return rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return rejectWithValue({
        error: "Network Error During Get Invoices",
      });
    }
  }
);
export const getPaymentMethod = createAsyncThunk(
  "catalog/payments/get-payment-method",
  async (data, thunkAPI) => {
    try {
      const { token } = thunkAPI.getState().auth;
      const response = await axios.get(
        `payments/get-payment-method/${data.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        toast.error(err.response.data.error);
        return thunkAPI.rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return thunkAPI.rejectWithValue({
        error: "Network Error During Get Payment Method Info",
      });
    }
  }
);
export const updatePaymentMethod = createAsyncThunk(
  "catalog/payments/update-payment-method",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const response = await axios.put("payments/update-payment-method", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        toast.error(err.response.data.error);
        return rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return rejectWithValue({
        error: "Network Error During Update Payment Method Info",
      });
    }
  }
);

export const saveFeedback = createAsyncThunk(
  "catalog/feedback/save",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("feedback/save-feedback", data);
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        toast.error(err.response.data.error);
        return thunkAPI.rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return thunkAPI.rejectWithValue({
        error: "Network Error During During Save Feedback",
      });
    }
  }
);
export const saveUninstalledEmail = createAsyncThunk(
  "catalog/scraping/save-uninstalled-email",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        "scraping/save-uninstalled-user-email",
        data
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          error: err.response.data.error,
          status: err.response.status,
          message: err.response.data.message,
        });
      }
      return thunkAPI.rejectWithValue({
        error: "Network Error During During Saving Uninstalled Users",
      });
    }
  }
);

const initialState = {
  productList: [],
  feedback: {},
  invoices: null,
  paymentMethodInfo: null,
  subscription: null,
  paymentIntentData: null,
  filters: PAGINATION,
  totalRows: 0,
  loading: null,
  paymentLoading: false,
  showTableError: null,
  error: null,
  message: null,
};

const catalog = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setState: (state, { payload: { field, value } }) => ({
      ...state,
      [field]: value,
    }),
    setFilter: (state, { payload: { field, value } }) => {
      const { filters } = state;
      const filterIndex = findIndex(filters, (item) => item.field === field);
      if (filterIndex === -1) {
        if (value) {
          filters.push({
            field,
            value,
          });
        }
      } else if (value || value == 0) {
        filters[filterIndex].value = value;
      }
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getASINData.pending, (state) => ({
        ...state,
        productList: [],
        loading: true,
        showTableError: null,
      }))
      .addCase(getASINData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          productList: payload.data,
          loading: null,
          showTableError: false,
        };
      })
      .addCase(getASINData.rejected, (state, { payload }) => ({
        ...state,
        error: payload.error,
        message: payload.message,
        loading: false,
        showTableError: true,
      }))
      .addCase(getSubscription.pending, (state) => ({
        ...state,
        productList: [],
        loading: true,
        showTableError: null,
      }))
      .addCase(getSubscription.fulfilled, (state, { payload }) => {
        return {
          ...state,
          subscription: payload?.data,
          loading: null,
          done: payload?.success || false,
        };
      })
      .addCase(getSubscription.rejected, (state, { payload }) => ({
        ...state,
        error: payload.error,
        message: payload.message,
        loading: false,
        showTableError: true,
      }))
      .addCase(createCustomer.pending, (state) => ({
        ...state,
        paymentLoading: true,
      }))
      .addCase(createCustomer.fulfilled, (state, { payload }) => ({
        ...state,
        paymentLoading: false,
        // customerData: payload?.data,
      }))
      .addCase(createCustomer.rejected, (state, { payload }) => ({
        ...state,
        paymentLoading: false,
      }))
      .addCase(createSubscription.pending, (state) => ({
        ...state,
        paymentLoading: true,
      }))
      .addCase(createSubscription.fulfilled, (state, { payload }) => ({
        ...state,
        paymentLoading: false,
        // subscriptionData: payload?.data,
      }))
      .addCase(createSubscription.rejected, (state, { payload }) => ({
        ...state,
        paymentLoading: false,
      }))
      .addCase(deleteSubscription.pending, (state) => ({
        ...state,
        paymentLoading: true,
      }))
      .addCase(deleteSubscription.fulfilled, (state, { payload }) => ({
        ...state,
        paymentLoading: false,
        message: payload?.message,
      }))
      .addCase(deleteSubscription.rejected, (state, { payload }) => ({
        ...state,
        paymentLoading: false,
        error: payload?.error,
        message: payload?.message,
      }))
      .addCase(getInvoices.pending, (state) => ({
        ...state,
        loading: true,
        done: false,
      }))
      .addCase(getInvoices.fulfilled, (state, { payload }) => ({
        ...state,
        invoices: payload?.data,
        totalRows: payload?.totalRows,
        loading: false,
        done: payload?.success || false,
      }))
      .addCase(getInvoices.rejected, (state, { payload }) => ({
        ...state,
        error: payload?.error,
        message: payload?.message,
        loading: false,
        done: payload?.success || false,
      }))
      .addCase(getPaymentMethod.pending, (state) => ({
        ...state,
        loading: true,
        done: false,
      }))
      .addCase(getPaymentMethod.fulfilled, (state, { payload }) => ({
        ...state,
        loading: false,
        paymentMethodInfo: payload?.data,
        message: payload?.message,
        done: payload?.success || false,
      }))
      .addCase(getPaymentMethod.rejected, (state, { payload }) => ({
        ...state,
        error: payload?.error,
        message: payload?.message,
        loading: false,
        done: payload?.success || false,
      }))

      .addCase(updatePaymentMethod.pending, (state) => ({
        ...state,
        loading: true,
        done: false,
      }))
      .addCase(updatePaymentMethod.fulfilled, (state, { payload }) => ({
        ...state,
        loading: false,
        message: payload?.message,
        done: payload?.success || false,
      }))
      .addCase(updatePaymentMethod.rejected, (state, { payload }) => ({
        ...state,
        error: payload?.error,
        message: payload?.message,
        loading: false,
        done: payload?.success || false,
      }))

      .addCase(saveFeedback.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(saveFeedback.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        loading: null,
      }))
      .addCase(saveFeedback.rejected, (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export const { setState, clearState, setFilter, setPageNumber, setPageSize } =
  catalog.actions;

export default catalog.reducer;
