import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { merge } from "lodash";
import Table from "../../CustomStyled/Table";
import Spinner from "../../CustomStyled/Spinner";

const ProductList = () => {
  const { productList, loading } = useSelector((state) => state.catalog);
  const [data, setData] = useState([]);
  const [isAsin, setIsAsin] = useState(false);

  useEffect(() => {
    if (productList?.length) {
      processProductList(productList);
      productList[0]?.asin ? setIsAsin(true) : setIsAsin(false);
    }
  }, [productList]); // eslint-disable-line react-hooks/exhaustive-deps

  /* eslint no-else-return: "off" */
  const processProductList = (productList) => {
    const resultArr = productList.map((item) => {
      if (item.identifiers?.length == 0) {
        if (item.itemId) {
          return item;
        } else {
          return {
            identifier: item.asin,
            variationType: item.variationType,
          };
        }
      } else {
        return {
          identifier: item.asin,
          variationType: item.variationType,
          ...item.identifiers.reduce((acc, identifier) => {
            if (!acc[identifier.type]) {
              acc[identifier.type] = [identifier.identifier];
            } else {
              acc[identifier.type].push(identifier.identifier);
            }
            return acc;
          }, {}),
        };
      }
    });
    setData(resultArr);
  };

  return loading ? (
    <SpinnerWrapper>
      <Spinner margin />
    </SpinnerWrapper>
  ) : (
    <Table data={data} isAsin={isAsin} />
  );
};

export default ProductList;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  div {
    width: 50px;
    height: 50px;
  }
`;
