import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import {
  BusinessOutlined,
  EmailOutlined,
  Navigation,
  PhoneOutlined,
} from "@mui/icons-material";
import { Fab } from "@mui/material";
// Assets
import logo from "../../../assets/img/logo-white.png";
import { BUSINESS_DETAIL } from "../../../utils/constants";

const Contact = (props) => {
  const { type } = props;
  const navigate = useNavigate();
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <WrapperFooter>
      <Link
        id="navigate-pointer"
        className="whiteColor animate pointer font13"
        to={
          type === "privacy" ? "privacy-policy" : type == "tos" ? "tos" : "home"
        }
        smooth
        offset={-80}
      >
        <StyledNavigator size="small">
          <Navigation />
        </StyledNavigator>
      </Link>{" "}
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter">
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth
              offset={-80}
              onClick={() => navigate("/")}
            >
              <img src={logo} alt="ecomsource.ai" width="170px" />
            </Link>
            <StyleP className="whiteColor font13">
              <em>
                © {getCurrentYear()} -{" "}
                <span className="purpleColor font13">ecomsource.ai</span> All
                Rights Reserved
              </em>
            </StyleP>
            <ContactInfo>
              <p className="whiteColor">
                <BusinessOutlined />
                Address: <em>{BUSINESS_DETAIL.address}</em>
              </p>
              <p className="whiteColor">
                <EmailOutlined />
                Email: <em>{BUSINESS_DETAIL.email}</em>
              </p>
              <p className="whiteColor">
                <PhoneOutlined />
                Phone #: <em>{BUSINESS_DETAIL.phoneNumber}</em>
              </p>
            </ContactInfo>
          </InnerWrapper>
        </div>
      </div>
    </WrapperFooter>
  );
};

export default Contact;

const WrapperFooter = styled.footer`
  width: 100%;
`;
const InnerWrapper = styled.div`
  padding: 0.625rem 0;
  @media (max-width: 768px) {
    padding: 1.1875rem 0;
    flex-direction: column;
    & > * {
      margin: 0.3125rem 0;
    }
  }
`;
const StyleP = styled.p`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.625rem;
  font-size: 0.75rem;
  color: #fff;
  & svg {
    font-size: 1.25rem;
    margin-right: 0.3125rem;
  }
`;
const StyledNavigator = styled(Fab)`
  position: fixed !important;
  bottom: 24px;
  right: 10px;
  z-index: 9999 !important;
`;
