import styled from "styled-components";

const ItemBox = ({ tag, title, text, action, author }) => {
  return (
    <WrapperBtn className="animate pointer" onClick={action ? () => action() : null}>
      <Wrapper className="whiteBg br-16 shadow">
        <h3 className="font20 extraBold">{title}</h3>
        <p className="font13" style={{ padding: "30px 0" }}>
          {text}
        </p>
        {author && <p className="font13 extraBold">{author}</p>}
        {tag && (
          <div className="flex">
            <p className="tag coralBg br-10 font13 extraBold">{tag}</p>
          </div>
        )}
      </Wrapper>
    </WrapperBtn>
  );
};

export default ItemBox;

const Wrapper = styled.div`
  width: 100%;
  height: 90%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  :hover {
    opacity: 0.5;
  }
`;
