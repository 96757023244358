import styled from "styled-components";

const FullButton = (props) => {
  const { title, action, className, ...rest } = props;
  return (
    <Wrapper
      {...rest}
      className={`animate br-16 ${className}`}
      onClick={action ? () => action() : null}
    >
      {title}
    </Wrapper>
  );
};

export default FullButton;

// prettier-ignore
const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  border: 1px solid ${(props) => (props.disabled ? "#a173ea" : props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.disabled ? "#a173ea" : props.border ? "transparent" : "#7620ff")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.disabled ? "#fff" : props.border ? "#707070" : "#fff")};
  &:hover {
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
    background-color: ${(props) => (props.disabled ? "#a173ea" : props.border ? "transparent" : "#580cd2")};
    color: ${(props) => (props.disabled ? "#fff" : props.border ? "#7620ff" : "#fff")};
  }
`;
