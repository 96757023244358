import styled from "styled-components";

const Select = ({ label, value, ...rest }) => {
  return (
    <Container>
      <div className="select-outline">
        <select value={value} {...rest}>
          <option defaultValue="">US</option>
          <option>CA</option>
          <option>MX</option>
          <option>BR</option>
          {/* <option>ES</option> */}
          {/* <option>GB</option> */}
          {/* <option>FR</option> */}
          {/* <option>NL</option> */}
          {/* <option>DE</option> */}
          {/* <option>IT</option> */}
          {/* <option>SE</option> */}
          {/* <option>PL</option> */}
          {/* <option>TR</option> */}
          {/* <option>AE</option> */}
          {/* <option>IN</option> */}
          {/* <option>SG</option> */}
          {/* <option>AU</option> */}
          {/* <option>JP</option> */}
        </select>
        <span className="border" />
        <label>{label}</label>
      </div>
    </Container>
  );
};

export default Select;

const Container = styled.div`
  width: 15%;
  max-height: 41px;
  border: none;
  margin-left: 100px;
  @media (max-width: 440px) {
    margin-left: 90px;
  }
  @media (max-width: 320px) {
    margin-left: 70px;
  }

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    max-height: 20px;
    padding: 0px 2px 1px;
  }
  .select-outline {
    width: 150%;
    position: relative;
    display: flex;

    select {
      font-size: 14px;
      padding: 18px 12px 12px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 8px;
      color: rgb(116, 132, 143);
      &:focus {
        outline: none;
      }
      &:focus ~ label,
      &:not([value=""]) ~ label {
        top: -7px;
        font-size: 14px;
        color: #6f5a9a;
        background: #fff;
        border-radius: 10px;
        padding: 0px 5px;
        max-width: 100%;
        @media (max-width: 425px) {
          left: 0px;
          font-size: 65%;
        }
      }
      &:hover ~ .border {
        border: 1px solid #6f5a9a !important;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        pointer-events: none;
        transition: all 0.1s ease;
      }
      &:focus ~ .border {
        border: 2px solid #6f5a9a !important;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        pointer-events: none;
        transition: all 0.1s ease;
      }
    }

    label {
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 12px;
      top: 9px;
      transition: 0.2s ease;
    }

    .border {
      border: 1px solid rgb(116, 132, 143);
      border-radius: 8px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      pointer-events: none;
      transition: all 0.3s ease;
    }
  }
`;
