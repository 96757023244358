import { Route, Routes } from "react-router-dom";
import LandingPage from "../components/pages/LandingPage";
import Feedback from "../components/pages/Feedback";
import PrivacyTerms from "../components/pages/PrivacyPolicy";
import PageNotFound from "../components/pages/PageNotFound";
import TOS from "../components/pages/TOS";

const PagesRoute = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<LandingPage />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="privacy-policy" element={<PrivacyTerms />} />
        <Route path="terms-of-service" element={<TOS />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default PagesRoute;
