import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
// MUI
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
// assets/modules
import FullButton from "../CustomStyled/Buttons/FullButton";
import {
  createCustomer,
  createSubscription,
  getSubscription,
  setFilter,
  setState,
  updatePaymentMethod,
} from "../../redux/reducers/catalog";
import OverlayLoading from "../CustomStyled/OverlayLoading";
import { getPaymentIcon } from "../../utils/generators";
import cvc from "../../assets/paymentIcons/cvc.png";

const Checkout = (props) => {
  const { onClose, email, type } = props;
  const dispatch = useDispatch();
  // const location = useLocation();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const type = searchParams.get("type");
  const stripe = useStripe();
  const elements = useElements();
  const { paymentLoading, loading } = useSelector((state) => state.catalog);
  const { userId } = useSelector((state) => state.auth);

  const [checkoutObj, setCheckoutObj] = useState({});
  const [cardNumberObject, setCardNumberObject] = useState({});
  const [expiryDateObject, setExpiryDateObject] = useState({});
  const [cvcObject, setCVCObject] = useState({});

  useEffect(() => {
    email && setCheckoutObj({ ...checkoutObj, email });
  }, [email]);

  const handleInputChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    setCheckoutObj({
      ...checkoutObj,
      [name]: value,
    });
  };

  // add validations
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!checkoutObj.privacyTerms) {
        toast.info("Please Accept Terms & Conditions to Proceed Further");
        return;
      }
      const email = event.target.email.value;
      if (!stripe || !elements) {
        return;
      }
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) return;
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });

      if (error) {
        console.log("Error", error);
        dispatch(setState({ field: "paymentLoading", value: false }));
        return;
      }
      const customerData = {
        email,
      };
      dispatch(setFilter({ field: "status", value: "active" }));
      dispatch(createCustomer(customerData)).then(async ({ payload }) => {
        if (payload.success) {
          const subscriptionData = {
            customerId: payload.data?.customerId,
            email,
            paymentMethodId: paymentMethod.id,
            userId,
          };
          if (type === "changeCard") {
            await dispatch(updatePaymentMethod(subscriptionData));
          } else {
            await dispatch(createSubscription(subscriptionData));
          }
          userId && dispatch(getSubscription({ userId }));
          onClose();
        }
      });
    } catch (err) {
      toast.error("Something went wrong");
      dispatch(setState({ field: "paymentLoading", value: false }));
      console.log("🚀 ~ file: Checkout.js:109 ~ err:", err.message);
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      {(paymentLoading || loading) && <OverlayLoading />}
      <Label>Email</Label>
      <Input
        type="email"
        name="email"
        placeholder="example@gmail.com"
        value={checkoutObj.email || ""}
        onChange={handleInputChange}
        disabled={email}
        required
      />
      {/* <PaymentElement /> */}
      <Label>Card Number</Label>
      <Box
        className="card-number-container stripe-field"
        style={{ borderColor: cardNumberObject.error ? "red" : "" }}
      >
        <CardNumberElement onChange={(e) => setCardNumberObject(e)} />
        <img
          src={getPaymentIcon(cardNumberObject)}
          width="30px"
          alt="card-icon"
        />
      </Box>
      <p className="error-info">{cardNumberObject?.error?.message}</p>
      <Box className="card-details-container">
        <Box className="expiry-date-container">
          <Label>Expiration Date</Label>
          <Box
            className="stripe-field stripe-expiry"
            style={{ borderColor: expiryDateObject.error ? "red" : "" }}
          >
            <CardExpiryElement onChange={(e) => setExpiryDateObject(e)} />
          </Box>
          <p className="error-info w-130">{expiryDateObject?.error?.message}</p>
        </Box>
        <Box className="cvc-date-container">
          <Label>CVC/CVV</Label>
          <Box
            className="stripe-field stripe-cvc card-number-container"
            style={{ borderColor: cvcObject.error ? "red" : "" }}
          >
            <CardCvcElement onChange={(e) => setCVCObject(e)} />
            <img src={cvc} width="30px" height="18px" alt="card-icon" />
          </Box>
          <p className="error-info w-130">{cvcObject?.error?.message}</p>
        </Box>
      </Box>
      <FormGroup>
        <FormControlLabel
          className="font13"
          control={
            <Checkbox
              name="privacyTerms"
              checked={checkoutObj.privacyTerms || false}
              onClick={handleInputChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            <Typography>
              Agree with{" "}
              <StyledLink to="/terms-of-service" className="" target="_blank">
                Terms of Service
              </StyledLink>{" "}
              and{" "}
              <StyledLink to="/privacy-policy" className="" target="_blank">
                Privacy Policy
              </StyledLink>
            </Typography>
          }
          required
        />
      </FormGroup>
      <ButtonWrapper className="flexSBNull">
        <FullButton
          type="button"
          disabled={!stripe || !elements}
          title="Close"
          action={() => onClose()}
          className="payment-button"
        />
        <FullButton
          type="submit"
          disabled={!stripe || !elements || !checkoutObj.privacyTerms}
          title={type ? "Change" : "Pay"}
          className="payment-button"
        />
      </ButtonWrapper>
    </FormWrapper>
  );
};

export default Checkout;

const FormWrapper = styled.form``;
const ButtonWrapper = styled.div``;
const Label = styled.label`
  font-size: 0.93rem;
`;
const Input = styled.input`
  width: 90%;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  background-color: #fff;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
`;
const StyledLink = styled(Link)`
  color: #9f62ff;
  &:hover {
    color: #7620ff;
  }
`;
