import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const PublicRoute = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  // const searchParamsObject = Object.fromEntries(
  //   Array.from(searchParams.entries())
  // );
  // console.log("All parameters in searchParams:", searchParamsObject);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      if (redirect === "buy") {
        navigate("/?payment=true");
      }
      if (pathname === "/auth/sign-in" && search === "?type=forget-password") {
        navigate("/dashboard/reset-password?type=forget-password");
      }
      if (accessiblePaths["auth"]?.includes(pathname)) {
        navigate(pathname);
      }
    }
  }, [token, redirect, pathname]);

  return token && !accessiblePaths["auth"]?.includes(pathname) ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;

const accessiblePaths = {
  auth: ["/auth/forget-password"],
  user: ["/"],
};
