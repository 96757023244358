import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import moment from "moment";
// MUI
import { Box, Paper, Typography } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
// Assets/Modules
import { getInvoices, setFilter } from "../../../redux/reducers/catalog";
import OverlayLoading from "../../CustomStyled/OverlayLoading";
import MuiDataGrid from "../../CustomStyled/MuiDataGrid";
import { PAGE_SIZE_OPTIONS, PAGINATION_MODEL } from "../../../utils/constants";
import RenderCell from "../../CustomStyled/RenderCell";

const Invoice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useSelector((state) => state.auth);
  const { paymentLoading, invoices, totalRows } = useSelector(
    (state) => state.catalog
  );
  const [paginationModel, setPaginationModel] = useState(PAGINATION_MODEL);
  // prettier-ignore

  useEffect(() => {
    dispatch(getInvoices());
  }, []);

  // const handleInputChange = ({ target }) => {
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const { name } = target;
  //   setSubscriptionObj({
  //     ...subscriptionObj,
  //     [name]: value,
  //   });
  // };
  const calculateTotal = (data) => {
    return data?.reduce((total, item) => total + item.amountPaid, 0);
  };
  const onPaginationModelChange = (paginationObj) => {
    const { page, pageSize } = paginationObj;
    if (pageSize != paginationModel.pageSize) {
      dispatch(setFilter({ field: "page", value: 0 }));
      setPaginationModel({ page: 0, pageSize });
    } else {
      dispatch(setFilter({ field: "page", value: page }));
      setPaginationModel(paginationObj);
    }
    dispatch(setFilter({ field: "pageSize", value: pageSize }));
    dispatch(getInvoices());
  };

  return (
    <Wrapper id="invoice">
      {paymentLoading ? (
        <OverlayLoading />
      ) : (
        <Box className="ps-5">
          <Typography variant="h6" sx={{ margin: 2 }}>
            Subscription Invoices
          </Typography>
          <Paper elevation={2}>
            <MuiDataGrid
              rows={invoices || []}
              columns={columns}
              tableHeight={"80vh"}
              // checkbox={true}
              rowHeight={40}
              // Row Selection
              // selectedRows={adminPanelObject.selectedItemsIds}
              // getSelectedRows={handleOnSelectionRows}
              // rowSelectionClick={false}
              // // FILTERATION
              // filterMode="server"
              // filterModel={FILTER_MODEL_UNVERIFIED}
              // getFilterModel={handleFilterModelChange}
              // // PAGINATION
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              rowCount={totalRows || 0}
              // slots={{ toolbar: CustomToolbar }}
            />
          </Paper>
        </Box>
      )}
    </Wrapper>
  );
};

export default Invoice;

const Wrapper = styled.section`
  width: 100%;
`;

const columns = [
  {
    field: "invoiceId",
    headerName: "InvoiceId",
    width: 300,
    // align: "center",
    filterable: false,
    // renderCell: (props) => <RenderCell {...props} type="Image" />,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
  },
  // {
  //   field: "name",
  //   headerName: "Lister",
  //   width: 100,
  //   valueGetter: (params) => params.row?.user?.name,
  // },
  {
    field: "invoiceNumber",
    headerName: "Invoice Number",
    width: 150,
    filterable: false,
  },
  {
    field: "amountPaid",
    headerName: "Amount Paid",
    width: 150,
    filterOperators: getGridStringOperators().filter(({ value }) =>
      ["equals"].includes(value)
    ),
    valueFormatter: (params) => `${params.value / 100} $`,
  },
  {
    field: "createdAt",
    headerName: "Date Created",
    width: 200,
    filterable: false,
    valueFormatter: (params) =>
      moment(params?.value).format("YYYY/MM/DD hh:mm A"),
  },
  {
    field: "",
    headerName: "Actions",
    sortable: false,
    width: 90,
    // disableClickEventBubbling: true,
    renderCell: (params) => <RenderCell {...params} type="previewInvoice" />,
  },
  // {
  //   field: "",
  //   headerName: "Actions",
  //   width: 120,
  //   align: "center",
  //   renderCell: (props) => <RenderCell {...props} type="Image" />,
  // },
  // {
  //   field: "listingType",
  //   width: 150,
  //   headerName: "Listing Type",
  //   filterOperators: getGridStringOperators().filter(({ value }) =>
  //     ["contains"].includes(value)
  //   ),
  // },
  // {
  //   field: "consigner",
  //   width: 150,
  //   headerName: "Consigner",
  //   filterOperators: getGridStringOperators().filter(({ value }) =>
  //     ["contains"].includes(value)
  //   ),
  // },
  // {
  //   field: "id",
  //   headerName: "Id",
  //   width: 90,
  //   filterOperators: getGridStringOperators().filter(({ value }) =>
  //     ["equals"].includes(value)
  //   ),
  // },
  // {
  //   field: "name",
  //   headerName: "Lister",
  //   width: 150,
  //   // editable: true,
  //   // sortable: false,
  //   filterable: false,
  // },
  // { field: "email", headerName: "Lister Email", width: 250, filterable: false },
  // {
  //   field: "upc",
  //   headerName: "UPC",
  //   width: 150,
  //   filterable: false,
  // },
  // {
  //   field: "sku",
  //   headerName: "SKU",
  //   width: 150,
  //   filterable: false,
  // },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 140,
  //   filterOperators: getGridStringOperators().filter(({ value }) =>
  //     ["equals"].includes(value)
  //   ),
  //   renderCell: (props) => <StatusIconsRender {...props} />,
  // },
];
