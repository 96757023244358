import axios from "axios";
import { clearLocalStorageKey } from "../utils/generators";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5400/api/v1/"
    : "https://dropshipping-ext.parallelloop.io/api/v1";

axios.defaults.baseURL = baseURL;

axios.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      const auth = JSON.parse(
        JSON.parse(localStorage?.getItem("persist:root"))?.auth
      );
      const token = auth?.token;
      if (token) {
        await clearLocalStorageKey("persist:root");
        window.location.href = "/auth/sign-in";
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
