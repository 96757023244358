import React from "react";
import { CircularProgress, Backdrop, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
  },
}));

const OverlayLoading = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
      <Typography variant="h6">Loading...</Typography>
    </Backdrop>
  );
};

export default OverlayLoading;
