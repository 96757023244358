import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
// MUI
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// Assets / Modules
import TopNavbar from "../Nav/TopNavbar";
import Footer from "./LandingPage/Footer";
import theme from "../../style/theme/mui-theme";

const TOS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [privacyObj, setPrivacyObj] = useState({});

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  const handleOnSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper id="tos">
        <TopNavbar />
        <div className="lightBg mt-50">
          <div className="container">
            <HeaderInfo>
              <FormGroup sx={{ display: "block" }}>
                <FormControlLabel
                  className="font13"
                  control={
                    <IconButton onClick={() => navigate("/")}>
                      <ChevronLeftIcon />
                    </IconButton>
                  }
                  label="Back to Home Page"
                />
              </FormGroup>
              <h1 className="mt-16">
                TERMS OF SERVICE &ndash; Last Updated (01
                <small className="ordinals">st</small> December, 2023)
              </h1>
            </HeaderInfo>
          </div>
        </div>
        <div className="container">
          <div className="row feeback-row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-16">
              {/* <h1>Terms and Conditions</h1> */}
              {/* <p>Effective Date: [Insert Effective Date]</p> */}
              {/* <p> */}
              These terms and conditions ("Terms") govern your use of [Your
              Website Name] ("Website") and constitute a legally binding
              agreement between you ("User" or "You") and [Your Company Name]
              ("Company," "We," "Us," or "Our"). By accessing and using this
              Website, you agree to comply with these Terms. If you do not agree
              with these Terms, please refrain from using the Website.
              {/* </p> */}
              <h2>1. Acceptance of Terms</h2>
              <p>
                <strong>1.1: </strong>
                By using this Website, you affirm that you have read,
                understood, and agreed to abide by these Terms and any other
                applicable policies or guidelines.
              </p>
              <p>
                <strong>1.2: </strong>
                These Terms are subject to change without notice, and it is your
                responsibility to review them regularly for updates.
              </p>
              {/* More sections of your terms and conditions here */}
              <h2>2. Use of the Website</h2>
              <p>
                <strong>2.1: </strong>
                The Website is intended for personal, non-commercial use. You
                must not use the Website for any illegal or unauthorized
                purpose.
              </p>
              <p>
                <strong>2.2: </strong>
                You agree not to engage in any activity that may disrupt,
                damage, or interfere with the proper functioning of the Website
                or any related services.
              </p>
              <h2>3. User Accounts</h2>
              <p>
                <strong>3.1: </strong>
                To access certain features or services on the Website, you may
                be required to create a user account. You are responsible for
                maintaining the confidentiality of your account credentials.
              </p>
              <p>
                <strong>3.2: </strong>
                You must notify the Company immediately if you suspect any
                unauthorized access to your account.
              </p>
              <h2>4. Privacy Policy</h2>
              <p>
                <strong>4.1: </strong>
                The collection, use, and protection of personal information are
                governed by our Privacy Policy. By using the Website, you agree
                to our Privacy Policy's terms.
              </p>
              <h2>5. Intellectual Property</h2>
              <p>
                <strong>5.1: </strong>
                The Website and its contents, including text, images, logos, and
                software, are protected by copyright and other intellectual
                property laws.
              </p>
              <p>
                <strong>5.2: </strong>
                You may not use, reproduce, distribute, or modify any part of
                the Website's content without prior written permission from the
                Company.
              </p>
              <h2>6. Termination</h2>
              <p>
                <strong>6.1: </strong>
                The Company reserves the right to terminate or suspend services
                immediately and without notice if you breach any of these Terms
                or engage in activities that may harm the Website's integrity or
                security.
              </p>
              <h2>7. Limitation of Liability</h2>
              <p>
                <strong>7.1: </strong>
                To the extent permitted by applicable law, the Company shall not
                be liable for any consequential, indirect, or special damages,
                including but not limited to lost profits, data loss, or
                business interruption.
              </p>
              <h2>8. Governing Law</h2>
              <p>
                <strong>8.1: </strong>
                These Terms are governed by and interpreted in accordance with
                the laws of [Your State], excluding its conflict of laws
                principles. Both parties consent to the exclusive jurisdiction
                of the state and federal courts located in [Your City].
              </p>
              <h2>9. User Responsibilities</h2>
              <h3>9.1 Compliance with Applicable Laws: </h3>
              <p>
                <strong>9.1.1: </strong>
                Users must comply with all local, state, and federal laws and
                regulations while using the Website.
              </p>
              <h3>9.2 Prohibited Conduct: </h3>
              <p>
                <strong>9.2.1: </strong>
                Users are prohibited from engaging in any conduct that may harm,
                harass, or infringe upon the rights of others.
              </p>
              <h2>10. Payment and Billing (if applicable)</h2>
              <h3>10.1 Payment Terms: </h3>
              {/* <h6>&#8226; Non-Refundable Payments: </h6> */}
              <p>
                <strong>10.1.1 Non-Refundable Payments: </strong>
                All payments made for our services are non-refundable. In the
                event of subscription cancellation, the service will remain
                active for the current month period.
              </p>
              <h3>10.2 Refunds: </h3>
              <p>
                <strong>10.2.1: </strong>
                No refunds will be issued for the remaining time.
              </p>
              <h2>11. Third-Party Services</h2>
              <h3>11.1 External Links:</h3>
              <p>
                <strong>11.1.1: </strong>
                If your website links to third-party websites or services,
                clarify that you are not responsible for the content or
                practices of these external sites.
              </p>
              <h3>11.2 Third-Party Products:</h3>
              <p>
                <strong>11.2.1: </strong>
                If your website sells or promotes third-party products or
                services, describe the terms and conditions governing these
                offerings.
              </p>
              <h2>12. Changes to the Terms</h2>
              <h3>12.1 Notification:</h3>
              <p>
                Explain how and when users will be informed of changes to the
                terms and conditions, and how they can accept or reject these
                changes.
              </p>
              <h2>13. Copyright and DMCA</h2>
              <h3>13.1 Copyright Infringement Claims:</h3>
              <p>
                <strong>13.1.1: </strong>
                Provide information on how users can submit copyright
                infringement claims and how you respond to such claims, in
                compliance with the Digital Millennium Copyright Act (DMCA).
              </p>
              <h2>14. Governing Law and Venue for Disputes</h2>
              <h3>14.1 Alternative Dispute Resolution (ADR):</h3>
              <p>
                <strong>14.1.1: </strong>
                Specify if users are required to attempt alternative dispute
                resolution methods, such as negotiation or mediation, before
                pursuing legal action.
              </p>
              <h2>15. Force Majeure</h2>
              <h3>15.1 Unforeseeable Events:</h3>
              <p>
                <strong>15.1.1: </strong>
                Explain that the Company is not responsible for any delays or
                failures to perform its obligations caused by unforeseeable
                circumstances beyond its control.
              </p>
              <h2>16. Assignment of Rights</h2>
              <h3>16.1 Transfer of Rights:</h3>
              <p>
                <strong>16.1.1: </strong>
                Outline the conditions under which the Company may assign or
                transfer its rights and obligations under the terms.
              </p>
              <h2>17. Entire Agreement</h2>
              <h3>17.1 Modifications:</h3>
              <p>
                <strong>17.1.1: </strong>
                Clarify that the terms and conditions, as amended from time to
                time, constitute the entire agreement between the Company and
                users, superseding all prior agreements or understandings.
              </p>
              <h2>18. Survival of Terms</h2>
              <h3>18.1 Termination:</h3>
              <p>
                <strong>18.1.1: </strong>
                Specify which terms will survive the termination or expiration
                of a user's account or use of the Website.
              </p>
              <h2>19. Miscellaneous</h2>
              <p>
                <strong>19.1: </strong>
                The failure of either party to enforce any rights or provisions
                of these Terms shall not be considered a waiver of such rights
                or provisions.
              </p>
              <p>
                <strong>19.2: </strong>
                In the event that any provision of these Terms is found to be
                invalid or unenforceable, the remaining provisions shall remain
                in full force and effect.
              </p>
              <p>
                <strong>19.3: </strong>
                For any questions or concerns regarding these Terms, please
                contact us at [Contact Email] or [Contact Phone Number].
              </p>
              <p>
                These Terms and Conditions constitute the entire agreement
                between you and [Your Company Name] concerning your use of the
                Website. Thank you for using our services.
              </p>
            </div>
            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-16">
            <p>
              Welcome to Parallelloop, LLC. These terms and conditions
              (&ldquo;Agreement&rdquo;) establish the agreement between Ecomsource, a
              Michigan-based limited liability company (&ldquo;Company&rdquo;), and any
              corporation, LLC, partnership, sole proprietorship, individual, or
              entity that utilizes our services (&ldquo;Client&rdquo;). This Agreement takes
              effect when either the Company provides services to the Client or
              when the Client uses our System, whichever occurs first
              (&ldquo;Effective Date&rdquo;). Your use of our System is subject to the terms
              and conditions outlined in this Agreement.
            </p>
            <p>
              By entering into this Agreement, both parties acknowledge that
              they have read, understood, and agreed to comply with its terms.
              The person signing this Agreement on behalf of the Client asserts
              their authority to bind the Client to these terms and conditions.
            </p>
            <h2 className="mt-16">DEFINITIONS.</h2>
            <p>
              In this Agreement, capitalized terms hold specific meanings as
              described below.
              <h6>1.1 "Client Data"</h6> refers to electronic data input or
              collected by the Client through the System, including data
              collected by other Users.
            </p>
            <p>
              <h6>1.2 "Disclaimer"</h6> denotes the Company&rsquo;s disclaimer,
              currently available on our website at
              https://ecomsource.ai/disclaimer/.
            </p>
            <p>
              <h6>1.3 "Privacy Policy"</h6> signifies the Company&rsquo;s privacy
              policy, accessible at
              <StyledLink to="https://ecomsource.ai/privacy-policy.">
                https://ecomsource.ai/privacy-policy.
              </StyledLink>

            </p>
            <p>
              <h6>1.4 "System"</h6> encompasses the Company&apos;s ecomsource.ai
              website, the Walmart Fulfillment Services fee calculator, the WM
              Seller Tools extension, Repricer, Rank Tracker, Wholesale Scan
              Tool, WFS Calculator, and any other software-as-a-service along
              with related training materials provided by the Company.
            </p>
            <p>
              <h6>1.5 "User"</h6> refers to any individual or company utilizing
              the System on behalf of the Client or through the Client&apos;s
              account or credentials, whether authorized or not.
            </p>
            <p>
              <h6>1.6 "Matched Listing"</h6> means a listing of a product on any
              marketplace along with information about where or from whom that
              product can be purchased, whether it is a retail store,
              wholesaler, vendor, or similar information.
            </p>
            <p>
              <h6>1.7 "Purchase"</h6> indicates any purchase made by a user of a
              Service.
            </p>
            <p>
              <h6>1.8 "Service"</h6> encompasses any service provided by the
              Company, including but not limited to the System, Ecom Source
              subscriptions and products, Proxy Tracking credits, Matched
              Listings, 2-Step Dropshipping, or any additional features offered
              by the Company or its affiliates.
            </p>
            <p>
              <h6>1.9 "2-Step"</h6> denotes the Service currently known as
              2-Step Dropshipping.
            </p>
            <p>
              <h6>2.0 "Warehouse Services"</h6> covers any service related to
              Ecom Source partner warehouses, suppliers, supplier partnerships,
              or dropship partnerships, including but not limited to warehouse
              fulfillment services, 2-step dropshipping services, returns, and
              storage.
            </p>
            <p>
              <h6>2.1 "Shipping Services"</h6> refers to any service connected
              to shipping carriers such as the United States Postal Service
              ("USPS"), United Parcel Service ("UPS"), FedEx, or any other
              carrier service.
            </p>
            <h2 className="mt-16">THE SYSTEM</h2>
            <p>
              <h4>2.1 Use of the System</h4>
              During the agreed-upon term, the Client is granted non-exclusive
              and limited access to the System, subject to the Company&apos;s
              discretion to terminate the Client&apos;s access.
            </p>
            <p>
              <h4>2.2 Policies</h4>
              The Client&apos;s use of the System is contingent on adherence to
              the Privacy Policy and the Company&apos;s Disclaimer, both of
              which are integrated into this Agreement.
            </p>
            <p>
              <h4>2.3 Automated Ordering</h4>
              The System incorporates an automated ordering feature that
              purchases products automatically based on the Client&apos;s
              preferences and software settings. Additional fees may apply for
              this service. The Company does not guarantee 100% uptime for this
              feature, and its functionality with different suppliers may vary.
            </p>
            <p>
              <h4>2.4 Affiliate Links</h4>
              The Company&apos;s automated ordering feature is partially
              supported by sponsored links from third-party merchants and
              advertisers. The Company may use specially formatted links through
              which it earns commissions on sales or clicks. These links may
              lead to third-party websites. The Company does not endorse or
              guarantee the products or services available through these links
              and is not responsible for third-party websites&apos; content or
              practices. By using the automated ordering service, you
              acknowledge and release the Company from any related liability.
            </p>
            <p>
              In accessing third-party websites through the automated ordering
              feature, you agree to bear any associated risks, and the Company
              is not liable for these risks. The Company does not intervene in
              disputes between the Client and third parties. You release the
              Company from any claims arising from such disputes.
            </p>
            <p>
              <h4>2.5 Tracking</h4>
              The System provides an inventory, price, and order management
              system that may assist the Client in obtaining tracking numbers
              for orders placed on various e-commerce platforms. Usage of this
              tracking service is voluntary, and the Company does not guarantee
              the accuracy or suitability of the tracking information for any
              specific marketplace.
            </p>
            <p>
              <h4>2.6 Repricing</h4>
              The Company offers a price management and insights tool that
              enables users to establish pricing strategies for their listings
              on different marketplaces. Usage of this Repricing tool is also
              voluntary, and the Company does not guarantee the accuracy of
              pricing data. The Company is not liable for any inaccuracies or
              damages that may arise from using the Repricing tool.
            </p>
            <p>
              <h4>2.7 Listing on Client Storefronts</h4>
              As part of our services, the Company may list items on the
              Client&apos;s storefront. The Client will be subject to fees for
              these listings, as per our current fee structure.
            </p>
            <p>
              <h4>2.8 Use of Non-Company Warehouse</h4>
              If the Client opts not to use the Company&apos;s warehouse for
              2-Step Services, the Client releases the Company from liability,
              costs, and damages associated with 2-Step Services and related
              shipments.
            </p>
            <h2 className="mt-16">FEES AND REIMBURSEMENT</h2>
            <p>
              The Company will provide the Client with current pricing
              information for our services. The Client agrees to pay the Company
              for any products or services ordered through the System according
              to our current pricing structure.
            </p>
            <p>
              <h4>3.1 Automated Ordering Fees</h4>
              Usage of the automated ordering feature may incur additional fees,
              which will be detailed in the Company&apos;s current fee
              structure.
            </p>
            <p>
              <h4>3.2 Service Fees</h4>
              Fees associated with the use of our services will be disclosed in
              the Company&apos;s current fee structure, which may be updated
              periodically. Any changes in fees will be communicated to the
              Client via email.
            </p>
            <p>
              <h4>3.3 Payment Processing</h4>
              The Client authorizes the Company to charge the Client&apos;s
              payment method on file for any fees associated with the use of our
              services. If the payment method fails or is declined, the Company
              may suspend or terminate the Client&apos;s access to the System.
            </p>
            <h4>3.3 Payment Processing</h4>
            <p>
              The Client hereby authorizes the Company to charge the Client's
              designated payment method on record for any fees associated with
              the use of our services. In the event that the payment method
              fails or is declined, the Company reserves the right to suspend or
              terminate the Client's access to the System.
            </p>

            <h4>3.4 Refunds and Disputes</h4>
            <p>
              The Client acknowledges and agrees that all fees paid to the
              Company are non-refundable. In the case of a dispute concerning
              fees or services, the Client commits to notifying the Company
              within 15 days of the dispute's occurrence.
            </p>

            <h2 className="mt-16">USER RESPONSIBILITIES</h2>
            <h4>4.1 Account Security</h4>
            <p>
              The Client is solely responsible for maintaining the security and
              confidentiality of their account credentials. Any activity taking
              place under the Client's account is their exclusive
              responsibility. The Client also agrees to promptly inform the
              Company of any unauthorized access or usage of their account.
            </p>

            <h4>4.2 Compliance with Laws</h4>
            <p>
              The Client agrees to utilize the System and our services in
              compliance with all applicable laws and regulations, including but
              not limited to those pertaining to intellectual property rights,
              online conduct, and acceptable content.
            </p>

            <h4>4.3 Prohibited Activities</h4>
            <p>
              The Client is strictly prohibited from engaging in any of the
              following activities, either directly or indirectly through the
              use of our services:
            </p>
            <ul>
              <li>Violating any applicable laws or regulations.</li>
              <li>
                Attempting to interfere with the functionality, security, or
                accessibility of the System.
              </li>
              <li>
                Reverse engineering, decompiling, or disassembling the System.
              </li>
              <li>
                Engaging in any fraudulent, unethical, or harmful activities.
              </li>
            </ul>

            <h4>4.4 Suspension and Termination</h4>
            <p>
              The Company reserves the right to suspend or terminate the
              Client's access to the System if the Client is found to be in
              violation of this Agreement or engaged in any prohibited
              activities. In the event of suspension or termination, the Client
              remains liable for all outstanding fees.
            </p>
            <h4>4.5 Account Termination</h4>
            <p>
              The Company reserves the right to terminate a client's account at
              its discretion without prior notice, should the client's actions
              or behavior be found in violation of this Agreement or harmful to
              the Company or its users.
            </p>

            <h4>4.6 Data Security</h4>
            <p>
              The client acknowledges that while the Company makes every effort
              to ensure the security and confidentiality of data stored on the
              System, it is ultimately the client's responsibility to maintain
              backups of critical data. The Company shall not be held liable for
              any loss or breach of data.
            </p>
            <h4>4.7 Compliance with Laws</h4>
            <p>
              The client agrees to comply with all applicable local, state, and
              federal laws, regulations, and ordinances while using the
              services. Any unlawful activities will result in the termination
              of the client's account.
            </p>

            <h4>4.8 Suspension of Services</h4>
            <p>
              The Company reserves the right to temporarily suspend services or
              access to the System for maintenance, upgrades, or other necessary
              activities. The client will be notified in advance of such
              suspensions whenever possible.
            </p>

            <h4>4.9 Third-Party Links</h4>
            <p>
              The System may contain links to third-party websites or resources.
              The Company is not responsible for the content or availability of
              these external sites and does not endorse or guarantee any
              information or services provided through them.
            </p>

            <h4>4.10 Changes to the Agreement</h4>
            <p>
              The Company reserves the right to modify this Agreement at any
              time. Clients will be notified of changes, and it is their
              responsibility to review and accept the updated terms to continue
              using the services.
            </p>
            <h4>4.11 Termination of Services</h4>
            <p>
              The Company reserves the right to terminate or suspend services
              immediately and without notice if the client breaches any terms or
              engages in activities that may harm the integrity or security of
              the services.
            </p>

            <h4>4.12 Service Availability</h4>
            <p>
              The Company will make reasonable efforts to ensure the
              availability of the services. However, the client acknowledges
              that service interruptions, delays, or technical issues may occur
              and that the Company is not liable for any resulting damages or
              losses.
            </p>

            <h4>4.13 User Accounts</h4>
            <p>
              To access certain features of the services, clients may be
              required to create user accounts. Clients are responsible for
              maintaining the confidentiality of their account credentials and
              should notify the Company of any unauthorized access to their
              account.
            </p>

            <h4>4.14 Privacy Policy</h4>
            <p>
              The use of the services is subject to our Privacy Policy, which
              outlines how personal information is collected, used, and
              protected. By using the services, clients agree to the terms of
              the Privacy Policy.
            </p>

            <h2 className="mt-16">INTELLECTUAL PROPERTY</h2>

            <h4>5.1 Ownership</h4>
            <p>
              All intellectual property rights, including but not limited to
              copyrights, trademarks, and patents, associated with the System
              and services, are the exclusive property of the Company. The
              client is granted a limited, non-transferable license to use these
              rights solely for the purpose of using the services.
            </p>

            <h4>5.2 User-Generated Content</h4>
            <p>
              Any content, materials, or data uploaded or provided by the client
              within the System remain the intellectual property of the client.
              The client grants the Company a non-exclusive, worldwide,
              royalty-free license to use, modify, or display this content
              solely for the purpose of delivering services.
            </p>

            <h4>5.3 Trademarks</h4>
            <p>
              All trademarks, logos, and service marks displayed on the System
              are the property of the Company or third parties. The client
              agrees not to use these marks for any purpose without explicit
              written permission.
            </p>
            <h2 className="mt-16">CONFIDENTIALITY</h2>

            <h4>5.4 Confidential Information</h4>
            <p>
              The client acknowledges that during the use of the services, they
              may have access to confidential information belonging to the
              Company. The client agrees not to disclose or use this information
              for any purpose other than the intended use of the services.
            </p>

            <h4>5.5 Non-Disclosure Agreement</h4>
            <p>
              The client and the Company may enter into a separate
              non-disclosure agreement, which will govern the handling and
              protection of confidential information more comprehensively.
            </p>

            <h2 className="mt-16">INDEMNIFICATION</h2>

            <h4>6.1 Client's Responsibility</h4>
            <p>
              The client agrees to defend, indemnify, and hold harmless the
              Company and its affiliates from any claims, damages, or losses
              arising from the client's use of the services, including any
              violation of this Agreement or the infringement of intellectual
              property rights.
            </p>

            <h2 className="mt-16">DISPUTE RESOLUTION</h2>
            <p>
              Any disputes arising from this Agreement shall be resolved through
              negotiation and mediation. If an amicable resolution cannot be
              reached, the parties may seek legal remedies in accordance with
              the laws of [Your State].
            </p>

            <h2 className="mt-16">ENTIRE AGREEMENT</h2>
            <p>
              This Agreement, including any attached exhibits and appendices,
              constitutes the entire agreement between the client and the
              Company regarding the use of the services and supersedes all prior
              agreements, understandings, or representations.
            </p>

            <h2 className="mt-16">ASSIGNMENT</h2>
            <p>
              The client may not assign or transfer any rights or obligations
              under this Agreement to any third party without the prior written
              consent of the Company.
            </p>

            <h2 className="mt-16">LIMITATION OF LIABILITY</h2>
            <p>
              The client acknowledges that the Company and its affiliates shall
              not be held liable for any direct, indirect, incidental, or
              consequential damages, including but not limited to loss of data,
              profits, or business interruption, arising from the use or
              inability to use the System or services.
            </p>

            <h2 className="mt-16">GOVERNING LAW</h2>
            <p>
              This Agreement is governed by and construed in accordance with the
              laws of the state of [Your State], without regard to its conflict
              of law principles.
            </p>

            <h2 className="mt-16">CONTACT INFORMATION</h2>
            <p>
              If you have any questions or concerns about this Agreement or need
              to contact us for any reason, please reach out to us at [Your
              Contact Information].
            </p>

            <h2 className="mt-16">DISCLAIMER</h2>
            <p>
              The Company hereby disclaims all warranties, whether expressed or
              implied, including but not limited to the warranties of
              merchantability and fitness for a particular purpose. The Client
              acknowledges that while the Company strives to provide accurate
              and reliable information, the System and services are provided "as
              is" and may not always be error-free or uninterrupted.
            </p>

            <h2 className="mt-16">PRIVACY POLICY</h2>
            <p>
              The Client commits to accepting and abiding by the terms
              delineated in the Company's Privacy Policy, which govern the
              collection, use, and sharing of personal and non-personal
              information. You can access the full Privacy Policy at
              https://ecomsource.ai/privacy-policy.
            </p>

            <h2 className="mt-16">CONCLUSION</h2>
            <p>
              This Agreement represents the entire understanding between the
              Company and the Client with regard to the subject matter discussed
              herein. Any modifications or amendments to this Agreement must be
              made in writing and must be mutually signed by both parties.
            </p>

            <p>
              For any questions, concerns, or support related to this Agreement,
              please do not hesitate to contact us at support@ecomsource.ai.
            </p>
          </div> */}
          </div>
        </div>
        <Footer type="tos" />
      </Wrapper>
    </ThemeProvider>
  );
};

export default TOS;

const Wrapper = styled.section`
  width: 100%;
  p {
    // text-indent: 4ch;
    padding-left: 4ch;
  }
  h1 {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 15px;
    margin-left: 20px;
  }
  h3 {
    font-size: 0.9375rem;
    font-weight: 600;
    margin-top: 10px;
  }
  h6 {
    margin-bottom: 0px;
  }
  strong {
    font-weight: 600;
  }
  .ordinals {
    position: relative;
    bottom: 8px;
    font-size: 15px;
  }
`;
const HeaderInfo = styled.div`
  padding: 40px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    color: black;
    -webkit-text-fill-color: black;
    background-color: transparent !important;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const ContactImgBox = styled.div`
  max-width: 100%;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
  img {
    width: 100%;
  }
  @media (max-width: 425px) {
    max-width: 85%;
    align-self: flex-start;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const StyledLink = styled(Link)`
  color: #9f62ff;
  &:hover {
    color: #7620ff;
  }
`;
