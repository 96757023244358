import styled, { keyframes } from "styled-components";

const Spinner = ({ margin, ...rest }) => {
  const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
  return <SpinnerWrapper {...rest} rotate360={rotate360} margin={margin} />;
};

const SpinnerWrapper = styled.div`
  animation: ${(props) => props?.rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  // margin: 0 auto 0;
  margin: ${(props) => (props.margin ? "10px auto" : "0 auto 0")};
`;

export default Spinner;
