import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startsWith } from "lodash";
// styling
import styled from "styled-components";
// assets
import HeaderImage from "../../../assets/img/header-img.png";
import QuotesIcon from "../../../assets/svg/Quotes";
import Dots from "../../../assets/svg/Dots";
// components
import FullButton from "../../CustomStyled/Buttons/FullButton";
import Input from "../../CustomStyled/Inputs/Input";
import Select from "../../CustomStyled/Select";
import { getASINData } from "../../../redux/reducers/catalog";

const UpcForm = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { loading, subscription } = useSelector((state) => state.catalog);
  const [upcData, setUpcData] = useState({
    identifier: "",
    locale: "US",
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setUpcData({ ...upcData, [name]: value.split(" ").join("") });
  };

  const scrollHandler = () => {
    const anchor = document.getElementById("upc-table");
    anchor?.scrollIntoView({
      scrollBehavior: "smooth",
      block: "end",
    });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const { identifier, locale } = upcData;
    const data = {
      // marketplace: "walmart",
      marketplace: "amazon",
      identifierType: "upc",
      identifier,
      locale,
      status: subscription?.status,
    };
    if (startsWith(identifier, "B0")) {
      // data.marketplace = "amazon";
      data.identifierType = "asin";
    }
    dispatch(getASINData(data));
    scrollHandler();
  };

  return (
    <>
      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide className="flexStartNull textCenter">
          <Form className="textJustifyInter" onSubmit={handleOnSubmit}>
            <h2 className="font40 extraBold">Enter your ASIN or UPC:</h2>
            <HeaderP className="font12 semiBold">
              Retrieve Amazon ASIN numbers correlating to your UPCs (Universal
              Product Codes). Convert ASIN to UPC (or UPC to ASIN) and retrieve
              an array of other data to help list your products and sell more
              competitively.
            </HeaderP>
            <WrapperInner>
              <Input
                // autoFocus
                name="identifier"
                label="Enter Identifier"
                value={upcData.identifier}
                onChange={handleOnChange}
              />
              <Select
                label="Region"
                name="locale"
                value={upcData.locale}
                onChange={handleOnChange}
              />
            </WrapperInner>
            <ButtonsRow className="flexNullCenter">
              <div style={{ width: "190px" }}>
                <FullButton disabled={loading} title="Search" />
              </div>
            </ButtonsRow>
            {subscription?.status !== "active" && (
              <Notification className="font12 semiBold">
                * Note: Free users are limited to 50 daily searches. Upgrade to
                a premium account for unlimited access.
              </Notification>
            )}
          </Form>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            <ImageDisable>
              <Img
                className="br-16"
                src={HeaderImage}
                alt="sample"
                style={{ zIndex: 9, maxWidth: "100%", maxHeight: "100%" }}
              />
              <QuoteWrapper className="flexCenter darkBg br-16">
                <QuotesWrapper>
                  <QuotesIcon />
                </QuotesWrapper>
                <p className="font15 whiteColor">
                  <em>Sample Image</em>
                </p>
              </QuoteWrapper>
            </ImageDisable>
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          </ImageWrapper>
          <GreyDiv className="whiteBg" />
        </RightSide>
      </Wrapper>
    </>
  );
};

export default UpcForm;

const Wrapper = styled.section`
  padding: 80px 30px !important;
  width: 100%;
  max-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }
  @media only screen and (max-width: 768px) {
    min-height: 0;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 3.125rem 0;
    padding: 0 10%;
  }
  @media (max-width: 768px) {
    justify-content: center;
    padding: 0 10% 0 6%;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
    padding: 0 6%;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;
const Form = styled.form`
  @media only screen and (max-width: 960px) {
    width: 73%;
  }
  @media only screen and (max-width: 425px) {
    width: 100%;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 10px 0px;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0;
    max-width: 100%;
  }
`;
const Notification = styled.div`
  max-width: 470px;
  padding: 10px 0px;
  line-height: 1.5rem;
  color: red;
  @media (max-width: 960px) {
    padding: 15px 0;
    max-width: 100%;
  }
`;
const GreyDiv = styled.div`
  width: 30.8%;
  height: 33%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 1440px) {
    width: 15.8%;
    height: 60%;
  }
  @media (max-width: 1024px) {
    width: 10%;
    height: 60%;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const ImageDisable = styled.div`
  @media only screen and (max-width: 960px) {
    display: none;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: -13%;
  top: 46.5%;
  bottom: 39%;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: -1;
  @media (max-width: 960px) {
    // right: 100px;
  }
  @media (max-width: 768px) {
    right: -150px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const WrapperInner = styled.section`
  display: flex;
  width: 94%;
  @media only screen and (max-width: 320px) {
    .input-outline {
      width: 145%;
    }
  }
`;
const ButtonsRow = styled.div`
  margin: 30px 0 0;
  @media (max-width: 960px) {
    padding: "0 0 0 7px";
  }
  @media only screen and (max-width: 425px) {
    button {
      padding: "0 0 0 7px";
    }
  }
`;
