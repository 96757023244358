import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
// Assets/Modules
import { getSubscription, setFilter } from "../../../redux/reducers/catalog";
import PricingTable from "../../Elements/PricingTable";
import PaymentModal from "../../Modals/Payment";

const Pricing = (props) => {
  const { redirect, cancelBtn } = props;
  const dispatch = useDispatch();
  const pricingRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const payment = searchParams.get("payment");
  const { userId, email, token, paymentLoading } = useSelector(
    (state) => state.auth
  );
  const { subscription } = useSelector((state) => state.catalog);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    dispatch(setFilter({ field: "status", value: "active" }));
    userId && dispatch(getSubscription({ userId }));
  }, []);

  useEffect(() => {
    const paymentActive = payment === "true" && !subscription;
    if (paymentActive && pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setOpenPopup(paymentActive);
  }, [subscription]);

  const handleOpenPopup = (val) => {
    if (token && email) {
      val === "buy"
        ? !subscription && setOpenPopup(true)
        : cancelBtn
        ? cancelBtn()
        : navigate("/dashboard/subscription?type=cancelSubs");
    } else {
      navigate("/auth/sign-in?redirect=buy");
    }
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
    navigate(redirect || "/");
  };

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <p className="font13">
              &#x1f680; &#x1f680; &#x1f680; All premium features are free for
              now. &#x1f680; &#x1f680; &#x1f680;
              <br />
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexAroundNull" id="pricing">
            <TableBox>
              <PricingTable
                icon="roller"
                price="Free"
                title="Starter"
                text="Includes free features"
                offers={[
                  {
                    name: "50 Search Requests per day on Ecomsource",
                    type: "warning",
                  },
                  {
                    name: "50 Search Requests per day on UPC Extension",
                    type: "warning",
                  },
                  { name: "Support via email", type: "tick" },
                  { name: "Instant Support via phone/chat", type: "warning" },
                  { name: "Access to facebook community group", type: "tick" },
                ]}
                action={() => navigate(redirect || "/")}
                btnTitle="Free"
              />
            </TableBox>
            <TableBox ref={pricingRef}>
              <PricingTable
                icon="monitor"
                price="$10/mo"
                taxPrice="6% inclusive sales tax"
                title="Basic"
                text="Buy to begin unlimited product search requests."
                offers={[
                  {
                    name: "Unlimited Search Requests on Ecomsource",
                    type: "tick",
                  },
                  {
                    name: "Unlimited Search Requests on UPC Extension",
                    type: "tick",
                  },
                  { name: "Support via email", type: "tick" },
                  { name: "Instant Support via phone/chat", type: "tick" },
                  { name: "Access to facebook community group", type: "tick" },
                ]}
                action={(val) => handleOpenPopup(val)}
                btnTitle={
                  subscription?.status === "active" ? "Subscribed" : "Buy"
                }
                cancelBtnTitle={
                  subscription?.cancelAtPeriodEnd ? "Cancelled" : "Cancel"
                }
                cancelAtPeriodEnd={subscription?.cancelAtPeriodEnd}
                disabled={subscription && subscription.status === "active"}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
      {/* MODALS */}
      {/*  type is if customer has already a payment method */}
      <PaymentModal open={openPopup} onClose={handleClosePopup} email={email} />
    </Wrapper>
  );
};

export default Pricing;

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  padding-bottom: 20px;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
