import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { isEmpty } from "lodash";
// MUI
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  AccountCircle,
  Logout,
  PersonAdd,
  Settings,
} from "@mui/icons-material";
// assets/modules
import { signOut } from "../../../redux/reducers/auth";
import { HeaderWrapper } from "./style";
import Logo from "../../../assets/img/logo-white.png";

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { name } = useSelector((state) => state?.auth || {});
  const { login, onClick } = props;
  // let user = {};
  // if (!isEmpty(parentUser)) user = parentUser;
  // else user = currentUser;
  // let splitedName = user?.full_name?.toUpperCase()?.split(' ') || [];
  // let abbreviatedName = `${splitedName?.[0]?.[0]}${splitedName?.[1]?.[0]}`;
  // const isOwner = user?.email == 'steven@wraithco.com';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(signOut());
    setAnchorEl(null);
  };
  const resetAndNavigate = async () => {
    // const reducers = isOwner ? ['adminUsers'] : ['affiliate'];
    // await dispatch({ type: 'RESET_APP', reducers });
    // isOwner ? navigate('/admin/user') : navigate('/affiliate');
  };

  return (
    <HeaderWrapper>
      <div className="d-flex justify-content-between align-items-center h-100 header">
        {!login ? (
          <img
            src={Logo}
            alt="no-log"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        ) : (
          ""
        )}
        {login && (
          <>
            <div className="logo-box d-flex">
              {/* <AiOutlineMenu onClick={onClick} /> */}
              <img
                src={Logo}
                alt="no-log"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
                width={"150px"}
              />
              {/* <div className="white-divider h-30" /> */}
              {/* <h1 className="mb-0 text-white">Inventory</h1> */}
            </div>
            <div className="logn-session">
              <div className="white-divider" />
              {/* <span
                className="icon-graph add-margin cursor-pointer"
                onClick={() => navigate("/referal-users")}
              ></span>
              <span
                className="icon-mointer fs-28 mr-31 cursor-pointer"
                onClick={() =>
                  window.open("https://thefbamachine-com.mykajabi.com/")
                }
              ></span> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography> */}
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {name.slice(0, 1)}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Avatar />
                  </ListItemIcon>
                  {name}
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Avatar />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Avatar />
                  </ListItemIcon>
                  My account
                </MenuItem> */}
                <Divider />
                {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Add another account
                </MenuItem> */}
                {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </>
        )}
      </div>
    </HeaderWrapper>
  );
};

export default Header;
