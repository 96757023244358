import styled from "styled-components";

const Input = ({ style, value, ...rest }) => {
  return <StyledInput style={style} {...rest} value={value} />;
};

export default Input;

const StyledInput = styled.input`
  // background: rgba(255, 255, 255, 0.15);
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  width: 95%;
  padding: 1rem;
  border: 1px solid #74848f;
  outline: none;
  color: #3c354e !important;
  font-size: 1rem;
  font-weight: bold;
  &:-webkit-autofill {
    // -webkit-box-shadow: 0 0 0 0px;
  }
  &:focus {
    display: inline-block;
    box-shadow: 0 0 0 0.1rem #755abe;
    backdrop-filter: blur(12rem);
    border-radius: 10px;
  }
  &::placeholder {
    color: #b9abe099;
    font-weight: 100;
    font-size: 1rem;
  }
`;
