import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
// MUI
import {} from "@mui/material";
// Assets/Modules
import {
  deleteSubscription,
  getSubscription,
  saveFeedback,
} from "../../../redux/reducers/catalog";
import Pricing from "../../pages/LandingPage/Pricing";
import ResponsiveDialog from "../../Modals/ResponsiveDialog";
import OverlayLoading from "../../CustomStyled/OverlayLoading";
import { trimStrings } from "../../../utils";

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams(location);
  const type = searchParams.get("type");
  const { userId, name, email } = useSelector((state) => state.auth);
  const { subscription, paymentLoading, loading } = useSelector(
    (state) => state.catalog
  );
  // const [subscriptionObj, setSubscriptionObj] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    type === "cancelSubs" && setOpenDialog(true);
  }, [type]);

  const handleClose = () => {
    setOpenDialog(false);
    navigate("/dashboard/subscription?type=");
  };
  // const handleInputChange = ({ target }) => {
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const { name } = target;
  //   setSubscriptionObj({
  //     ...subscriptionObj,
  //     [name]: value,
  //   });
  // };
  const handleCancelBtn = () => {
    setOpenDialog(true);
  };
  const handleCancelSubscription = async (message) => {
    if (message.trim()) {
      const data = {
        subscriptionId: subscription.id,
        name,
        email,
        subject: "Cancel Subscription",
        message,
      };
      const trimmedData = trimStrings(data);
      await dispatch(saveFeedback(trimmedData));
      await dispatch(deleteSubscription({ userId }));
      userId && dispatch(getSubscription({ userId }));
      handleClose();
    } else {
      toast.info("Please Provide Feedback");
    }
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Wrapper id="subscription">
      {(paymentLoading || loading) && <OverlayLoading />}
      <div className="whiteBg px-2">
        <div className="container">
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {/* <Box className="shadow p-3 my-5 bg-body rounded"> */}
              <Pricing
                redirect={location.pathname}
                cancelBtn={handleCancelBtn}
              />
              {/* </Box> */}
            </div>
          </div>
        </div>
      </div>
      {/* MODALS */}
      <ResponsiveDialog
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleCancelSubscription}
      />
    </Wrapper>
  );
};

export default Subscription;

const Wrapper = styled.section`
  width: 100%;
`;
