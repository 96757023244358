import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { InlineWidget } from "react-calendly";
// Modules/Components
import { saveFeedback, saveUninstalledEmail } from "../../redux/reducers/catalog";
import FullButton from "../CustomStyled/Buttons/FullButton";
import OverlayLoading from "../CustomStyled/OverlayLoading";
// images
import ContactImg1 from "../../assets/img/amazon-icon.jpg";
import ContactImg2 from "../../assets/img/walmart-icon.jpg";
import ContactImg3 from "../../assets/img/upc-lookup-icon.jpg";

const Feedback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading } = useSelector((state) => state.catalog);
  const [calendly, setCalendly] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    const email = location?.search?.split("?")[1];
    if (email) {
      const data = { email };
      dispatch(saveUninstalledEmail(data));
    }
  }, [location, dispatch]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    dispatch(saveFeedback(data)).then(() => {
      setData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    });
  };

  return (
    <Wrapper id="contact">
      {loading && <OverlayLoading />}
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">
              We&apos;re sorry to see you go &#x1f614;
            </h1>
            <p className="font18">
              Your departure from our platform is truly disheartening,
              Communication is the cornerstone of conflict resolution. Shall we
              begin?
              <br />
            </p>
          </HeaderInfo>
          <div className="row feeback-row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              {calendly === false ? (
                <>
                  <Form id="form" onSubmit={handleOnSubmit}>
                    <label className="font13">Name:</label>
                    <input
                      type="text"
                      id="fname"
                      name="name"
                      autoComplete="true"
                      value={data.name}
                      onChange={handleOnChange}
                    />
                    <label className="font13">Email:</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="true"
                      value={data.email}
                      onChange={handleOnChange}
                    />
                    <label className="font13">Subject:</label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      autoComplete="true"
                      value={data.subject}
                      onChange={handleOnChange}
                    />
                    <textarea
                      rows="4"
                      cols="50"
                      type="text"
                      id="message"
                      name="message"
                      value={data.message}
                      onChange={handleOnChange}
                    />
                  </Form>
                  <SumbitWrapper className="flexSpaceCenter">
                    <ButtonInput
                      type="submit"
                      form="form"
                      value="Send Message"
                      className="pointer animate br-16"
                      style={{ maxWidth: "220px" }}
                    />
                    <p
                      className="font20 extraBold"
                      style={{ marginTop: "5px" }}
                    >
                      /OR
                    </p>
                    <ButtonInput
                      type="button"
                      className="pointer animate br-16"
                      value="Schedule a Meeting"
                      style={{ maxWidth: "220px" }}
                      onClick={() => setCalendly(true)}
                    />
                  </SumbitWrapper>
                </>
              ) : (
                <>
                  <InlineWidget url="https://calendly.com/ecomsource/30min" />
                  <SumbitWrapper className="flexCenter">
                    <FullButton
                      title="Go Back"
                      style={{ maxWidth: "220px", marginTop: "30px" }}
                      action={() => setCalendly(false)}
                    />
                  </SumbitWrapper>
                </>
              )}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div
                style={{ width: "50%" }}
                className="flexNullCenter flexColumn"
              >
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="br-10 w-200" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="br-10 w-200" />
                </ContactImgBox>
              </div>
              <div style={{ marginTop: "15%" }}>
                <ContactImgBox>
                  <img src={ContactImg3} alt="office" className="br-10 w-200" />
                </ContactImgBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Feedback;

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 40px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    color: black;
    -webkit-text-fill-color: black;
    background-color: transparent !important;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const ContactImgBox = styled.div`
  max-width: 100%;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
  img {
    width: 100%;
  }
  @media (max-width: 425px) {
    max-width: 85%;
    align-self: flex-start;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
