import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// MUI
import { IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import {
  CancelOutlined,
  SendOutlined,
  VerifiedOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
// assets/modules

const RenderCell = (props) => {
  const { type } = props;
  return type === "previewInvoice" ? <InvoicePreview {...props} /> : null;
};

export default RenderCell;

// ----------------------------------------------------------------
// Preview Invoice Icon Component
// ----------------------------------------------------------------
const InvoicePreview = (props) => {
  const { row } = props;
  const navigate = useNavigate();
  return (
    <StyledIconButton onClick={() => navigate(`preview/${row.id}`)}>
      <VisibilityOutlined />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: "auto !important",
}));

// // ----------------------------------------------------------------
// // Allow Access MUI Data Grid
// // ----------------------------------------------------------------
// const AccessSwitch = (props) => {
//   const dispatch = useDispatch();
//   const { row } = props || {};
//   const [switchCheck, setSwitchCheck] = useState({});

//   useEffect(() => {
//     if (row) {
//       setSwitchCheck({ id: row.id, checked: row.allowAccess });
//     }
//   }, []);

//   const handleSwitches = (e, id) => {
//     const { checked } = e.target;
//     dispatch(AllowAccessProofing({ id, checked }));
//     setSwitchCheck({ id, checked }); // for controlled switching
//   };

//   return (
//     <SwitchRoot>
//       <FormControlLabel
//         control={
//           <Switch
//             checked={switchCheck.checked || false}
//             onChange={(checked) => handleSwitches(checked, row.id)}
//           />
//         }
//       />
//     </SwitchRoot>
//   );
// };

// const SwitchRoot = styled("div")(({ theme }) => ({
//   cursor: "pointer",
//   margin: "auto",
// }));

// // ----------------------------------------------------------------
// // Editable Text MUI Data Grid
// // ----------------------------------------------------------------
// const Editable = (props) => {
//   // console.log("🚀 ~ file: RenderCell.js:80 ~ Editable ~ props:", props);
//   const dispatch = useDispatch();
//   const { row, formattedValue } = props || {};
//   const [textObj, setTextObj] = useState({ name: "" });

//   useEffect(() => {
//     if (row) {
//       const { name, id } = row;
//       setTextObj({ name });
//       // dispatch(ChangeUsername({ name, id }));
//     }
//   }, []);

//   const handleText = (e, id) => {
//     const { checked } = e.target;
//     dispatch(AllowAccessProofing({ id, checked }));
//     setSwitchCheck({ id, checked }); // for controlled switching
//   };

//   return (
//     <BoxRoot>
//       {/* <Typography onBlur={(e) => console.log("damn", e)}>
//         {textObj.name}
//       </Typography> */}
//       <TextField
//         value={textObj.name}
//         onChange={(e) => setTextObj(e.target.value)}
//         // onBlur={handleNameBlur}
//         // onBlur={(e) => console.log("damn")}
//       />
//     </BoxRoot>
//   );
// };

// // ----------------------------------------------------------------
// // Image Component
// // ----------------------------------------------------------------
// const RenderImage = (props) => {
//   const {
//     value,
//     row: { rawItemsImages, itemsImages },
//   } = props || {};
//   const [srcObj, setSrcObj] = useState("");

//   const handleImageRendering = (imageList) => {
//     return imageList.map((item) => {
//       const { id, type, uri } = item;
//       if (type === "mainImage") {
//         return (
//           <img
//             src={srcObj || uri}
//             key={id}
//             alt={`no image`}
//             onError={() => setSrcObj(noImage)}
//           />
//         );
//       }
//     });
//   };

//   return (
//     <BoxRoot>
//       {value
//         ? handleImageRendering(value)
//         : rawItemsImages
//         ? handleImageRendering(rawItemsImages)
//         : itemsImages && handleImageRendering(itemsImages)}
//     </BoxRoot>
//   );
// };

// const BoxRoot = styled(Box)(({ theme }) => ({
//   "& img": {
//     width: 75,
//     height: 75,
//   },
// }));

// // ----------------------------------------------------------------
// // Status Icon for Pending/Drafted
// // ----------------------------------------------------------------
// const StatusIconsRender = ({ value }) => {
//   return value === "Drafted" ? (
//     <VerifiedOutlined color="success" />
//   ) : (
//     <CancelOutlined sx={{ color: "#ff0000" }} />
//   );
// };
