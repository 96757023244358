export const toCommas = (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const trimStrings = (obj) => {
  const trimmedObj = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "string") {
      trimmedObj[key] = obj[key].trim();
    } else {
      trimmedObj[key] = obj[key];
    }
  });
  return trimmedObj;
};
