import { useSelector } from "react-redux";
import styled from "styled-components";
import { FaExclamationCircle } from "react-icons/fa";
import Icons from "./Icons";

const Table = (props) => {
  const { data, isAsin } = props;
  const ExclamationCircle =
    "linear-gradient(to right, #6637b1 0%, #8b4bf2 40%, #ff0053 100%)";
  const { error, message, showTableError } = useSelector(
    (state) => state.catalog
  );

  return (
    <div id="upc-table">
      {showTableError ? (
        <IconsContainer>
          <Icons color={ExclamationCircle}>
            <FaExclamationCircle /* size={70} */ />
          </Icons>
          {error ? <p>{error.name || error}</p> : <p>{message}</p>}
        </IconsContainer>
      ) : (
        data?.length > 0 && (
          <StyledTable>
            <DataItems id="titles" className="semiBold">
              {isAsin ? (
                <>
                  <div>ASIN</div>
                  <div>EAN</div>
                  <div>UPC</div>
                  <div>VariationType</div>
                </>
              ) : (
                <>
                  <div>Product ID</div>
                  <div>Item ID</div>
                  <div>UPC</div>
                  <div>VariationType</div>
                </>
              )}
            </DataItems>
            {data?.map((item, index) => (
              <DataItems id="data" className="semiBold" key={index}>
                {isAsin ? (
                  <>
                    <div>{item.identifier || "-"}</div>
                    <div>{item.EAN?.[0] || "-"}</div>
                    <div>{item.UPC?.[0] || "-"}</div>
                    <div>{item.variationType || "-"}</div>
                  </>
                ) : (
                  <>
                    <div>{item.productId || "-"}</div>
                    <div>{item.itemId || "-"}</div>
                    <div>{item.upc || "-"}</div>
                    <div>{item.variationType || "-"}</div>
                  </>
                )}
              </DataItems>
            ))}
          </StyledTable>
        )
      )}
    </div>
  );
};

export default Table;

const StyledTable = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid purple;
  width: fit-content;
  border-radius: 20px;
  margin: auto 20% 40px;

  #titles {
    color: #fffff0;
    background-color: #7620ff;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    @media (max-width: 680px) {
      min-width: 111%;
    }
  }
  #data {
    :nth-of-type(odd) {
      background-color: rgb(245 245 245);
    }
    :last-child {
      border-bottom-left-radius: 17px;
      border-bottom-right-radius: 17px;
    }
    @media (max-width: 680px) {
      min-width: 111%;
      div {
        margin: auto;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    overflow-x: auto;
  }
  @media only screen and (max-width: 525px) {
    margin: auto auto 40px;
    div {
      font-size: 85%;
    }
    div > div {
      margin: 0 15px;
    }
  }
`;
const DataItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 1.125rem;
  div {
    margin: 0 20px;
    padding: 5px 10px;
    width: 12vw;
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    div {
      width: 14vw;
    }
  }
`;
const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 80px;
  div {
    width: 6.5rem;
    height: 6.5rem;
  }
  svg {
    width: 75%;
    height: 75%;
  }
  p {
    margin-top: 10px;
  }
`;
