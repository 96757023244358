import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
// Assets / Modules / Components
import CloseIcon from "../../assets/svg/CloseIcon";
import logo from "../../assets/img/logo-white.png";

const Sidebar = ({ sidebarOpen, toggleSidebar, token, signOut }) => {
  const navigate = useNavigate();
  const { productList } = useSelector((state) => state.catalog);

  const handleOnClick = () => {
    signOut();
    toggleSidebar(!sidebarOpen);
    window.location.href = "/";
  };
  const handleToggleSidebar = (path) => {
    toggleSidebar(!sidebarOpen);
    navigate(path);
  };

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img src={logo} alt="parallelloop" width="170px" />
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>
      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => handleToggleSidebar("/#home")}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy
            smooth
            offset={-60}
          >
            Home
          </Link>
        </li>
        {productList?.length > 0 && (
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => handleToggleSidebar("/#upc-table")}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="upc-table"
              spy
              smooth
              offset={-60}
            >
              Table
            </Link>
          </li>
        )}
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => handleToggleSidebar("/#product-data")}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="product-data"
            spy
            smooth
            offset={-60}
          >
            Features
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => handleToggleSidebar("/#pricing")}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy
            smooth
            offset={-60}
          >
            Pricing
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => handleToggleSidebar("/#contact")}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy
            smooth
            offset={-60}
          >
            Contact
          </Link>
        </li>
        <li className="semiBold font15 pointer px-10">
          <Link
            activeClass="active"
            className="whiteColor"
            to="privacy-policy"
            spy
            smooth
            offset={-80}
            onClick={() => handleToggleSidebar("/privacy-policy")}
          >
            Privacy Policy
          </Link>
        </li>
        <li className="semiBold font15 pointer px-10">
          <Link
            activeClass="active"
            className="whiteColor"
            to="tos"
            spy
            smooth
            offset={-80}
            onClick={() => handleToggleSidebar("/terms-of-service")}
          >
            Terms of Service
          </Link>
        </li>
      </UlStyle>
      <UlBtnStyle className="flexSpaceCenter">
        {!token ? (
          <>
            <li className="semiBold font15 pointer">
              <Button
                onClick={() => navigate("/auth/sign-in")}
                className="br-16 whiteBg"
              >
                Log in
              </Button>
            </li>
            <li className="semiBold font15 pointer">
              <Button
                onClick={() => navigate("/auth/sign-up")}
                className="br-16 whiteColor blueBg"
              >
                Get Started
              </Button>
            </li>
          </>
        ) : (
          <>
            <li className="semiBold font15 pointer">
              <Button
                // $primary
                className="br-16"
                onClick={() => navigate("/dashboard/subscription")}
              >
                Dashboard
              </Button>
            </li>
            <li className="semiBold font15 pointer">
              <Button
                className="br-16 whiteColor blueBg"
                onClick={handleOnClick}
              >
                Sign Out
              </Button>
            </li>
          </>
        )}
      </UlBtnStyle>
    </Wrapper>
  );
};

export default Sidebar;

const Wrapper = styled.nav`
  width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  padding: 0 1.875rem;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 1.25rem 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 0.625rem 2.5rem 0px;
  li {
    margin: 1.25rem 0;
  }
`;
const UlBtnStyle = styled.ul`
  padding: 0.625rem 2.5rem 0px;
  li {
    margin: auto;
  }
`;
const Button = styled.button`
  padding: 0.625rem;
  &.whiteColor {
    background-color: #7620ff;
    &:hover {
      color: #fff;
      background-color: rgba(118, 32, 255, 0.8);
    }
  }
`;
