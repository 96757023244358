import { toast } from "react-toastify";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../config/axios";

export const signUp = createAsyncThunk(
  "auth/signup",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("auth/sign-up", data);
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        toast.error(err.response.data.error);
        return thunkAPI.rejectWithValue({
          error: err.response.data.error || err.response.data,
          message: err.response.data.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({ error: "Network Error" });
    }
  }
);
export const signIn = createAsyncThunk(
  "auth/signin",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/auth/sign-in", data);
      toast.success("Login successful!");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return thunkAPI.rejectWithValue({
          error: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({ err: "Network Error" });
    }
  }
);
export const CodeVerify = createAsyncThunk(
  "/auth/code-verify",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/code-verify", data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.error);
        return rejectWithValue({
          error: err.response.data,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ error: "Network Error" });
      }
    }
  }
);
export const ResendCode = createAsyncThunk(
  "/auth/resend-code",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/resend-code", data);
      toast.success(response.data.message);
      return { ...response.data, status: response.status };
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        toast.error(err.response.data);
        toast.error(err.response.data.error);
        return rejectWithValue({
          error: err.response.data,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ error: "Network Error" });
      }
    }
  }
);
export const ChangePassword = createAsyncThunk(
  "/auth/change-password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "/auth/change-password",
        { password: data.password },
        {
          headers: {
            Authorization: `bearer ${data.token}`,
          },
        }
      );
      toast.success(response.data.message);
      return { ...response.data, status: response.status };
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data.message);
        toast.error(err.response.data);
        toast.error(err.response.data.error);
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({ err: "Network Error" });
    }
  }
);

const initialState = {
  userId: "",
  name: "",
  email: "",
  password: "",
  token: "",
  isActive: null,
  error: "",
  message: "",
  status: "",
  errMessage: "",
  loading: null,
  loggedIn: null,
  done: null,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setState: (state, { payload: { field, value } }) => ({
      ...state,
      [field]: value,
    }),
    clearState: () => ({
      userId: "",
      name: "",
      email: "",
      password: "",
      token: "",
    }),
    signOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => ({
        ...state,
        loading: true,
        error: "",
        message: "",
        status: "",
        done: false,
      }))
      .addCase(signUp.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload.user,
        userId: payload.user.id,
        errMessage: payload?.message,
        loading: false,
        loggedIn: true,
        done: true,
      }))
      .addCase(signUp.rejected, (state, { payload }) => {
        console.log("🚀 ~ file: auth.js:167 ~ .addCase ~ payload:", payload);
        return {
          ...state,
          loading: false,
          done: false,
          error: payload?.error,
          message: payload?.message,
          status: payload?.status,
        };
      })

      .addCase(signIn.pending, (state) => ({
        ...state,
        loading: true,
        error: "",
        message: "",
        done: false,
      }))
      .addCase(signIn.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload.user,
        token: payload.token,
        userId: payload.userId,
        errMessage: payload?.message,
        loading: false,
        loggedIn: true,
      }))
      .addCase(signIn.rejected, (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload?.err || payload?.error,
        status: payload?.status,
      }))

      .addCase(CodeVerify.pending, (state) => ({
        ...state,
        loading: true,
        error: "",
        message: "",
        done: false,
      }))
      .addCase(CodeVerify.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload.user,
        token: payload.token,
        userId: payload.userId,
        message: payload?.message,
        loading: false,
        loggedIn: true,
      }))
      .addCase(CodeVerify.rejected, (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload?.error.error,
        message: payload?.error.message,
        status: payload?.status,
      }))

      .addCase(ResendCode.pending, (state) => ({
        ...state,
        loading: true,
        error: "",
        message: "",
        done: false,
      }))
      .addCase(ResendCode.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload.user,
        token: payload.token,
        userId: payload.userId,
        message: payload?.message,
        status: payload?.status,
        done: payload?.success,
        loading: false,
        loggedIn: true,
      }))
      .addCase(ResendCode.rejected, (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload?.error.error,
        message: payload?.error.message,
        status: payload?.status,
        done: payload?.success,
      }));
  },
});

export const { clearState, setState, signOut } = auth.actions;

export default auth.reducer;
