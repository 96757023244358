import styled from "styled-components";

const SectionWrapper = styled.section`
  .rccs {
    margin: 0;
    .rccs__name {
      display: none;
    }
  }
`;

export { SectionWrapper };
