import { useState } from "react";
import styled from "styled-components";
// Components
import ItemBox from "../../Elements/ItemBox";
import FullButton from "../../CustomStyled/Buttons/FullButton";

const ProductData = () => {
  const [loadData, setLoadData] = useState(false);
  const [loadPricing, setLoadPricing] = useState(false);
  const [loadAnalytics, setLoadAnalytics] = useState(false);

  return (
    <Wrapper id="product-data">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Available Data</h1>
            <p className="font13">
              You can collect, analyze, and export the following data.
              <br />
              Note, some attributes are available only on certain plans.
            </p>
          </HeaderInfo>
          <HeaderInfo className="font20 extraBold">Product Data:</HeaderInfo>
          {/* ********************** Product Data ********************** */}
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="ASIN"
                text="Amazon Standard Identification Number"
                // tag="company"
                // author="Luke Skywalker, 2 days ago"
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox title="UPC" text="Universal Product Code." />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox title="EAN" text="International Article Number (European Article Number)." />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox title="SKU" text="Stock-keeping Unit." />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox title="MPN" text="Manufacturer Part Number." />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Keywords"
                text="Search Amazon for keywords or titles of your products and return matching results."
              />
            </div>
            {loadData && (
              <>
                {htmlProductData.map((item, index) => {
                  return (
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={index}>
                      <ItemBox title={item.title} text={item.description} />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {loadData ? (
                <FullButton title="Show Less" action={() => setLoadData(false)} />
              ) : (
                <FullButton title="Load More" action={() => setLoadData(true)} />
              )}
            </div>
          </div>

          {/* ********************** Product Pricing ********************** */}
          <HeaderInfo className="font20 extraBold">Product Pricing:</HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Buybox New Landed Price!"
                text="The total buy box listing price and shipping price of a product in new condition."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox title="Buybox New Listing Price" text="The buy box listing price of a product in new condition." />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox title="Buybox New Shipping Price" text="The buy box shipping price of a product in new condition." />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Buybox Used Landed Price"
                text="The total buy box listing price and shipping price of a product in used condition."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox title="Buybox Used Listing Price" text="The buy box listing price of a product in used condition." />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Buybox Used Shipping Price"
                text="The buy box shipping price of a product in used condition."
              />
            </div>
            {loadPricing && (
              <>
                {ProductPricing.map((item, index) => {
                  return (
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={index}>
                      <ItemBox title={item.title} text={item.description} />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {loadPricing ? (
                <FullButton title="Show Less" action={() => setLoadPricing(false)} />
              ) : (
                <FullButton title="Load More" action={() => setLoadPricing(true)} />
              )}
            </div>
          </div>

          {/* ********************** Analytical Data ********************** */}
          <HeaderInfo className="font20 extraBold">Analytical Data:</HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Sales Rank"
                text="The Amazon Sales Rank assigned to a product within a certain category that represents sales performance. A lower number means a better ranking."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Total New Sellers"
                text="Number of sellers within a particular listing selling a new conditioned item."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Total Used Sellers"
                text="Number of sellers within a particular listing selling a used conditioned item."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Total Refurbished Sellers"
                text="Number of sellers within a particular listing selling a refurbished conditioned item."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Lowest New Feedback Rating"
                text="(98-100%, 95-97%, 90-94%, 80-89%, 70-79%, Less than 70%, or Just launched ) – Indicates the percentage of feedback ratings for the lowest priced seller on a new conditioned item that were positive over the past 12 months."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ItemBox
                title="Lowest New Feedback Count"
                text="The number of seller feedback ratings that have been submitted for the seller with the lowest-priced offer listing in the group of new conditioned items."
              />
            </div>
            {/* </div> */}
            {loadAnalytics && (
              <>
                {AnalyticalData.map((item, index) => {
                  return (
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={index}>
                      <ItemBox title={item.title} text={item.description} />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {loadAnalytics ? (
                <FullButton title="Show Less" action={() => setLoadAnalytics(false)} />
              ) : (
                <FullButton title="Load More" action={() => setLoadAnalytics(true)} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductData;

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

/**
 * Product Data for ItemBox
 */
const htmlProductData = [
  { title: "ISBN", description: "International Standard Book Number" },
  {
    title: "Parent ASIN",
    description:
      "Parent ASIN of a product listing. A listing has a parent ASIN if there are children ASINs (product variations) assigned. ",
  },
  {
    title: "Children ASIN",
    description:
      "The children ASINs of a product listing. These children ASINs typically represent variations of a parent ASIN like sizes or colors.",
  },
  { title: "Manufacturer", description: "The manufacturer name or brand name of the product" },
  { title: "Description", description: "Full product (editorial) description" },
  { title: "Model", description: "The product model" },
  { title: "Package Dimensions Height", description: "The shipping package height" },
  { title: "Package Dimensions Width", description: "The shipping package width" },
  { title: "Package Dimensions Length", description: "The shipping package length" },
  { title: "Package Dimensions Weight", description: "The shipping package weight" },
  { title: "Item Dimensions Height", description: "The product height" },
  { title: "Item Dimensions Width", description: "The product width" },
  { title: "Item Dimensions Length", description: "The product length" },
  { title: "Item Dimensions Weight", description: "The product weight" },
  { title: "Publication Date (Books)", description: "The date the book was published" },
  { title: "Edition (Books)", description: "The edition of the book if available" },
  { title: "Number of Pages (Books)", description: "Total number of pages the book has" },
  { title: "Trade-in Value", description: "The product trade-in value (primarily for books)" },
  { title: "Size", description: "The size of the product (i.e. Shoe size)" },
  { title: "Color", description: "The color of the product if available" },
  { title: "Merchant Name", description: "The seller's name of the buy box price" },
  {
    title: "5 Additional Images",
    description: "The primary image URL of the product and 4 additional image URLs if available",
  },
  { title: "Category", description: "The top level category of the product" },
  { title: "Subcategory", description: "The lowest level category of the product" },
  {
    title: "All Categories",
    description:
      "Includes the category hierarchy that the product is in (i.e. Electronics > Computers & Accessories > Tablets).",
  },
  { title: "Amazon Listing URL", description: "The URL of the main product page on Amazon" },
  { title: "Binding (Books)", description: "The binding of a book (i.e. Hardcover, Paperback, Spiral)" },
  { title: "Brand", description: "The brand name or manufacturer name of a product" },
  { title: "Label", description: "The label of a product, commonly a book (i.e. Scholastic)" },
  { title: "Number of Items", description: "Number of items in a product" },
  { title: "Package Quantity", description: "Quantity of items in a package" },
  { title: "Product Group", description: "The product category an item belongs to" },
  { title: "Product Type Name", description: "The Amazon 'type name' identifier, i.e. (CONSOLE_VIDEO_GAMES)" },
  { title: "Publisher (Books)", description: "The publisher of a book" },
  { title: "Release Date", description: "The release date of a book" },
  { title: "Small Image", description: "Primary product small image URL (normally 75px width)" },
  { title: "Medium Image", description: "Primary product medium image URL (normally 160px width)" },
  { title: "Large Image", description: "Primary product large image URL (normally 500px width)" },
  { title: "Features", description: "Bullet point descriptions on a product listing" },
  { title: "Studio", description: "The name of the studio, such as Warner Brothers, that produced a digital item" },
  { title: "Title", description: "The Amazon listing title of the product" },
];

/**
 * Product Pricing
 */
const ProductPricing = [
  {
    title: "Buybox Used Subcondition",
    description: "The buy box subcondition of a used product (i.e. New, Mint, Very Good, Good, Acceptable, Poor).",
  },
  {
    title: "Lowest Offer Listings",
    description: "Contains pricing information for the lowest offer listing for each offer listing group.",
  },
  {
    title: "Lowest New Fulfillment Channel",
    description:
      "The fulfillment channel - Merchant or FBA - of the seller with the absolute lowest price new condition offer (total list price + shipping).",
  },
  {
    title: "Lowest New Price",
    description:
      "The listing price of the lowest landed (listing + shipping) offer of a new item regardless of fulfillment channel.",
  },
  {
    title: "Lowest New Shipping",
    description:
      "The shipping price of the lowest landed (listing + shipping) offer of a new item regardless of fulfillment channel.",
  },
  {
    title: "Lowest Used Fulfillment Channel",
    description:
      "The fulfillment channel - Merchant or FBA - of the seller with the absolute lowest price used condition offer (total list price + shipping).",
  },
  {
    title: "Lowest Used Price",
    description:
      "The listing price of the lowest landed (listing + shipping) offer of a used item regardless of fulfillment channel.",
  },
  {
    title: "Lowest Used Shipping",
    description:
      "The shipping price of the lowest landed (listing + shipping) offer of a used item regardless of fulfillment channel.",
  },
  {
    title: "Lowest New Price FBA ",
    description: "The listing price of the lowest landed (listing + shipping) price, FBA, new item condition offer. ",
  },
  {
    title: "Lowest New Shipping FBA",
    description: "The shipping price of the lowest landed (listing + shipping) price, FBA, new condition offer.",
  },
  {
    title: "Lowest Used Price FBA",
    description: "The listing price of the lowest landed (listing + shipping) price, FBA, used condition offer.",
  },
  {
    title: "Lowest Used Shipping FBA",
    description: "The shipping price of the lowest landed (listing + shipping) price, FBA, used condition offer.",
  },
  {
    title: "Lowest New Price Merchant",
    description:
      "The listing price of the lowest landed (listing + shipping) price, merchant fulfilled, new condition offer. ",
  },
  {
    title: "Lowest New Shipping Merchant",
    description:
      "The shipping price of the lowest landed (listing + shipping) price, merchant fulfilled, new condition offer.",
  },
  {
    title: "Lowest Used Price Merchant",
    description:
      "The listing price of the lowest landed (listing + shipping) price, merchant fulfilled, used condition offer. ",
  },
  {
    title: "Lowest Used Shipping Merchant",
    description:
      "The shipping price of the lowest landed (listing + shipping) price, merchant fulfilled, used condition offer.",
  },
  { title: "Lowest Refurbished Price", description: "The lowest price of a refurbished product." },
  {
    title: "Has Super Saver Shipping",
    description:
      "A '0' represents no SSS and a '1' notes the buy box product offers Super Saver Shipping. SSS is commonly offered with prime as well. ",
  },
  {
    title: "List Price Amount",
    description: "The full retail price of the product suggested by the manufacturer or supplier.",
  },
];

/**
 * Analytical Data
 */
const AnalyticalData = [
  {
    title: "Lowest Used Feedback Rating",
    description:
      "(98-100%, 95-97%, 90-94%, 80-89%, 70-79%, Less than 70%, or Just launched ) \u2013 Indicates the percentage of feedback ratings for the lowest priced seller on a used conditioned item that were positive over the past 12 months.",
  },
  {
    title: "Lowest Used Feedback Count",
    description:
      "The number of seller feedback ratings that have been submitted for the seller with the lowest-priced offer listing in the group of used conditioned items. ",
  },
  {
    title: "Lowest New Feedback Rating FBA",
    description: "Applies to the lowest offer of FBA sellers. See 'Lowest New Feedback Rating' above. ",
  },
  {
    title: "Lowest New Feedback Count FBA",
    description: "Applies to the lowest offer of FBA sellers. See 'Lowest New Feedback Count' above. ",
  },
  {
    title: "Lowest Used Feedback Rating FBA",
    description: "Applies to the lowest offer of FBA sellers. See 'Lowest Used Feedback Rating' above. ",
  },
  {
    title: "Lowest Used Feedback Count FBA",
    description: "Applies to the lowest offer of FBA sellers. See 'Lowest Used Feedback Count' above.",
  },
  {
    title: "Lowest New Feedback Rating Merchant",
    description: "Applies to the lowest offer of Merchant fulfilled sellers. See 'Lowest New Feedback Rating' above.",
  },
  {
    title: "Lowest New Feedback Count Merchant",
    description: "Applies to the lowest offer of Merchant fulfilled sellers. See 'Lowest New Feedback Count' above.",
  },
  {
    title: "Lowest Used Feedback Rating Merchant",
    description: "Applies to the lowest offer of Merchant fulfilled sellers. See 'Lowest Used Feedback Rating' above. ",
  },
  {
    title: "Lowest Used Feedback Count Merchant",
    description: "Applies to the lowest offer of Merchant fulfilled sellers. See 'Lowest Used Feedback Count' above. ",
  },
  {
    title: "FBA Tier Level",
    description:
      "Fulfillment by Amazon Tier Level (i.e. Small Standard-Size, Large Standard-Size, Small Oversize, Medium Oversize, Large Oversize, Special Oversize).",
  },
  { title: "FBA Order Handling", description: "Fulfillment by Amazon Order Handling Fee." },
  { title: "FBA Pick & Pack", description: "Fulfillment by Amazon Pick and Pack Fee. " },
  { title: "FBA Weight Handling", description: "Fulfillment by Amazon Weight Handling Fee." },
  { title: "FBA Fee Total", description: "Fulfillment by Amazon Fee Total. " },
];
