import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { toast } from "react-toastify";
// MUI
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
// modules/styledComponents
import { CodeVerify, signUp } from "../../../redux/reducers/auth";
import Input from "../../CustomStyled/Inputs/InputSignIn";
import Button from "../../CustomStyled/Buttons/ButtonSignIn";
import Verification from "./Verification";
import OverlayLoading from "../../CustomStyled/OverlayLoading";
// assets
import WallPaper from "../../../assets/img/background.jpeg";
import ContactImg1 from "../../../assets/img/amazon-icon.jpg";
import ContactImg2 from "../../../assets/img/walmart-icon.jpg";
import ContactImg3 from "../../../assets/img/upc-lookup-icon.jpg";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const { loading, email } = useSelector((state) => state.auth);
  const [code, setCode] = useState("");
  const [signUpObj, setSignUpObj] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    // type: "code-verify",
  });

  useEffect(() => {
    if (code.length === 6) {
      const data = { code, email };
      dispatch(CodeVerify(data));
    }
  }, [code]);

  const handleInputChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    setSignUpObj({ ...signUpObj, [name]: value });
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
    const { email, password, privacyTerms } = signUpObj;
    if (!privacyTerms) {
      toast.info("Please Accept Our Terms & Conditions to Proceed Further");
    } else if (!email.trim() || !password.trim()) {
      toast.error("Invalid email or password");
    } else {
      dispatch(signUp(signUpObj)).then(({ payload }) => {
        if (payload.success) {
          setSignUpObj({ ...signUpObj, type: "code-verify" });
        }
      });
    }
  };

  return (
    <div className="lightBg p-80">
      {loading && <OverlayLoading />}
      <Title>Create Account</Title>
      <Grid className="row">
        {signUpObj.type !== "code-verify" ? (
          <GridItem className="col-xs-12 col-sm-12 col-md-12 col-lg-6 item1">
            <Form className="" onSubmit={handleOnSubmit}>
              <MainContainer>
                <WelcomeText>Welcome</WelcomeText>
                <InputContainer>
                  <div className="flex">
                    <Input
                      autoFocus
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={signUpObj.firstName}
                      onChange={handleInputChange}
                    />
                    <Input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={signUpObj.lastName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <Input
                    style={{ marginBottom: "10px" }}
                    type="email"
                    placeholder="Email"
                    name="email"
                    className="full-input"
                    value={signUpObj.email}
                    onChange={handleInputChange}
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    name="password"
                    className="full-input"
                    value={signUpObj.password}
                    onChange={handleInputChange}
                  />
                </InputContainer>
                <StyledFormGroup>
                  <FormControlLabel
                    className="font13"
                    control={
                      <Checkbox
                        name="privacyTerms"
                        checked={signUpObj.privacyTerms || false}
                        onClick={handleInputChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      <Typography>
                        Agree with{" "}
                        <StyledLink to="/terms-of-service" target="_blank">
                          Terms of Service
                        </StyledLink>{" "}
                        and{" "}
                        <StyledLink to="/privacy-policy" target="_blank">
                          Privacy Policy
                        </StyledLink>
                      </Typography>
                    }
                    required
                  />
                </StyledFormGroup>
                <ButtonContainer>
                  <Button content="Sign Up" />
                </ButtonContainer>
                <LoginWith>
                  <Link to={`/auth/sign-in?redirect=${redirect}`}>
                    OR SIGN IN
                  </Link>
                </LoginWith>
                <HorizontalRule />
                <Link to="/">Goto Main Page</Link>
                <ForgotPassword>Forgot Password ?</ForgotPassword>
              </MainContainer>
            </Form>
          </GridItem>
        ) : (
          <GridItem className="col-xs-12 col-sm-12 col-md-12 col-lg-6 item1">
            <MainContainer>
              <Verification code={code} setCode={setCode} />
            </MainContainer>
          </GridItem>
        )}
        <GridItem className="col-xs-12 col-sm-12 col-md-12 col-lg-6 flexCenter hide-on-mobile item2">
          <div className="flexNullCenter flexColumn">
            <ContactImgBox>
              <img src={ContactImg1} alt="office" className="br-10 w-270" />
            </ContactImgBox>
            <ContactImgBox>
              <img src={ContactImg2} alt="office" className="br-10 w-270" />
            </ContactImgBox>
          </div>
          <ContactImgBox>
            <img src={ContactImg3} alt="office" className="br-10 w-270" />
          </ContactImgBox>
        </GridItem>
      </Grid>
    </div>
  );
};

export default SignUp;

const Title = styled.h1`
  padding: 10px 0px 0px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  text-align: center;
  color: #292589;
`;
const Grid = styled.div`
  padding: 2%;
  height: 100%;
  width: 100%;
`;
const GridItem = styled.div`
  padding: 0px 20px;
  @media only screen and (min-width: 1440px) {
    display: flex;
    justify-content: center;
    &:nth-child(2) {
      & > div {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    &:nth-child(2) {
      margin-top: 10%;
      & > div {
        width: 100%;
      }
    }
  }
`;
const Form = styled.form`
  // background-image: url(${WallPaper});
  // background-size: cover;
  display: flex;
  justify-content: right;
  align-items: center;
  // height: 100vh;
  // width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}`;
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  @media only screen and (max-width: 320px) {
    height: 90vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    height: 90vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 425px) {
    height: 90vh;
  }

  @media only screen and (min-width: 768px) {
    height: 80vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 80vh;
    border: 3px solid #755abe;
  }
  @media only screen and (min-width: 1280px) {
    border: 3px solid #755abe;
    width: 40vw;
    height: 90vh;
  }
`;
const WelcomeText = styled.h2`
  margin: 1rem 0;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 65%;
  text-fill-color: #564e4e;
  input: -webkit-autofill {
    -webkit-text-fill-color: #564e4e;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  div > input {
    width: 45%;
    margin-bottom: 10px;
  }
  & > input {
    width: 100%;
  }
`;
const ButtonContainer = styled.div`
  margin: 1rem 0 2rem 0;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoginWith = styled.h5`
  cursor: pointer;
  a {
    &:hover {
      color: #7620ff;
    }
  }
`;
const HorizontalRule = styled.hr`
  width: 90%;
  height: 0.3rem;
  border-radius: 0.8rem;
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  background-color: #ebd0d0;
  margin: 1.5rem 0 1rem 0;
  backdrop-filter: blur(25px);
`;
const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 3rem 0;
  width: 80%;
`;
const ForgotPassword = styled.h4`
  cursor: pointer;
  margin: auto;
  &:hover {
    color: #7620ff;
  }
`;
const ContactImgBox = styled.div`
  max-width: 100%;
  margin: 10px 30px 10px 0;
  img {
    width: 100%;
  }
  @media (max-width: 425px) {
    img {
      width: 110% !important;
      height: auto !important;
    }
  }
`;
const StyledLink = styled(Link)`
  color: #9f62ff;
  &:hover {
    color: #7620ff;
  }
`;
const StyledFormGroup = styled(FormGroup)`
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0.625rem 0;
  & > label {
    margin-left: 0;
  }
`;
