const primaryColor = "#1565D8";
const secondaryColor = "#4E5969";
const primaryTextColor = "#0000";
const disabledColor = "#C4C4C4";
const outlineColor = "#E6E6E6";
const warningColor = "#FF9900";

const linkColor = "#556EE6";
const linkHover = "#0D79DF";
const successColor = "#009c34";
const successHover = "#0CAB7C";
const warningHover = "#D47407";
const errorColor = "#F52C50";
const errorHover = "#E30D0F";
const infoColor = "#2C99FF";
const darkColor = "#272B41";
const grayColor = "#C4C4C4";
const lightColor = "#9299B8";
const lightHover = "#e2e6ea";
const whiteColor = "#ffffff";
const dashColor = "#E3E6EF";
const whiteHover = "#5A5F7D";
const dangerColor = "#FF4D4F";
const dangerHover = "#E30D0F";
const borderColorLight = "#F1F2F6";
const borderColorNormal = "#E3E6EF";
const borderColorDeep = "#C6D0DC";
const bgGrayColorDeep = "#EFF0F3";
const bgGrayColorLight = "#F8F9FB";
const bgGrayColorNormal = "#BAD5ED";
const lightGrayColor = "#868EAE";
const tableHeadr = "#F8F9FA";
const tableButton = "#2B2B2B";
const columnsSelect = "rgba(98, 166, 226, 0.4);";
const graySolid = "#9299b8";
const pinkColor = "#8F5AFF";
const btnlg = "48px";
const btnsm = "36px";
const btnxs = "29px";
const lynch = "#607D8B";
const regentGray = "#78909C";
const cuttySark = "#546E7A";
const yourPink = "#FFC9C9";
const bostonBlue = "#3A80BD";
const tundora = "#4A4A4A";
const cobalt = "#0052B4";
const silverChalice = "#A3A3A3";
const tropicalBlue = "#C0DBF4";
const buttonOutlined = "rgba(50, 57, 78, 0.69)";
const outerSpace = "#263238";
const outerSpaceLite = "#343A40";
const doveGrey = "#646464";
const OlsoGrey = "#959A9E";
const boulder = "#757575";
const black = "#000000";
const stromGrey = "#727685";
const goldColor = "#FFD600";
const mercury = "#E6E6E6";
const comet = "#616980";
const dustyGrey = "#979797";
const OrangePeel = "#FF9900";
const aquaHaze = "#F8FAFB";
const abbey = "#515253";
const scienceBlue = "#0053D0";
const japaneseLaurel = "#00A707";
const mintCream = "mintcream";
const darkBlue = "#3d4afe";
const blue = "#1e90ff";
const darkPurple = "#0b093b";
const lightPurple = "#9a80ff";
const purple = "#7620ff";
const strangePurple = "#727de9";
const orange = "#f2b300";
const coral = "#c4ffff";
const green = "#49cb86";
const grey = "#707070";
const lightGrey = "#666666";
const light = "#f5f5f5";

const theme = {
  "primary-color": primaryColor, // primary color for all components
  "secondary-color": secondaryColor, // secondary color for all components
  "primary-text-color": primaryTextColor, // primary color for component
  "disabled-color": disabledColor, // disabled color
  "outline-color": outlineColor, // outline color
  "link-color": linkColor, // link color
  "link-hover": linkHover, // link color
  "success-color": successColor, // success state color
  "success-hover": successHover, // success state color
  "warning-color": warningColor, // warning state color
  "warning-hover": warningHover, // warning state color
  "error-color": errorColor, // error state color
  "error-hover": errorHover, // error state color
  "light-color": lightColor, // info state color
  "light-hover": lightHover, // info state color
  "white-color": whiteColor, // info state color
  "white-hover": whiteHover, // info state color
  "table-haeder": tableHeadr,
  "expand-button": tableButton,
  "dark-yellow": "#FFC700",
  "warning-alert": "#FFCB00",
  "abbey-color": abbey,
  "lynch-color": lynch,
  regent_gray: regentGray,
  cutty_sark: cuttySark,
  "your-pink": yourPink,
  "boston-blue": bostonBlue,
  "tundora-color": tundora,
  "cobalt-color": cobalt,
  "silverChalice-color": silverChalice,
  "tropical-Blue": tropicalBlue,
  "thundora-color": "#464646",
  "dove-grey": doveGrey,
  "strom-grey": stromGrey,
  "japanese-aurel": japaneseLaurel,
  "science-blue": scienceBlue,
  "black-color": black,
  "alabaster-color": "#FCFCFC",
  "pink-color": pinkColor,
  "dash-color": dashColor, // info state color
  "danger-color": dangerColor,
  "danger-hover": dangerHover,
  "font-family": "Montserrat, sans-serif",
  "font-size-base": "14px", // major text font size
  "text-color": darkColor, // major text color
  "text-color-secondary": grayColor, // secondary text color
  // "disabled-color": "rgba(0, 0, 0, 0.25)", // disable state color
  "border-radius-base": "0.25rem", // major border radius
  "border-radius-button": "2rem", // major border radius
  "border-radius-filled-textField-muliline": "1.3rem", // major border radius
  "border-color-base": "#d9d9d9", // major border color
  "box-shadow-base": "0 2px 8px rgba(0, 0, 0, 0.15)", // major shadow for layers
  "border-color-light": borderColorLight,
  "border-color-normal": borderColorNormal,
  "border-color-deep": borderColorDeep,
  "bg-color-light": bgGrayColorLight,
  "bg-color-normal": bgGrayColorNormal,
  "bg-color-deep": bgGrayColorDeep,
  "light-gray-color": lightGrayColor,
  "gray-solid": graySolid,
  "column-select": columnsSelect,
  "btn-height-large": btnlg,
  "btn-height-small": btnsm,
  "btn-height-extra-small": btnxs,
  "btn-default-color": darkColor,
  "button-Outlined": buttonOutlined,
  "outer-space": outerSpace,
  "outer-space-lite": outerSpaceLite,
  "olso-grey": OlsoGrey,
  "boulder-color": boulder,
  "collpase-table-background": "#62A6E2",
  gold_color: goldColor,
  "mercury-color": mercury,
  "comet-color": comet,
  dusty_grey: dustyGrey,
  orange_peel: OrangePeel,
  aqua_haze: aquaHaze,
  "mint-cream": mintCream,
  "dark-blue": darkBlue,
  blue,
  white: whiteColor,
  "dark-purple": darkPurple,
  "light-purple": lightPurple,
  purple,
  "strange-purple": strangePurple,
  orange,
  coral,
  green,
  grey,
  "light-grey": lightGrey,
  light,

  // Layout
  "edy-background": "#F4F5F7",
  "layout-header-background": "#ffffff",
  "layout-footer-background": "#fafafa",
  "layout-header-height": "64px",
  "layout-header-padding": "0 15px",
  "layout-footer-padding": "24px 15px",
  "layout-sider-background": "#ffffff",
  "layout-trigger-height": "48px",
  "layout-trigger-background": "#002140",
  "layout-trigger-color": "#fff",
  "layout-zero-trigger-width": "36px",
  "layout-zero-trigger-height": "42px",
  // Layout light theme
  "layout-sider-background-light": "#fff",
  "layout-trigger-background-light": "#fff",
  "layout-trigger-color-light": "rgba(0, 0, 0, 0.65)",

  // font-sizes
  "font-size-small": "10px",
  "base-font-size-small": "12px",
  "medium-font-size": "14px",
  "base-font-size": "16px",
  "title-font-size": "1.25rem",
  // font-weight
  "normal-weight": "400",
  "bold-weight": "600",

  // Placeholders
  "placeholder-color": "#979797",

  // Margin / Padding
};

export { theme };
