import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ReactCreditCards from "react-credit-cards-2";
import moment from "moment";
// MUI
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import {} from "@mui/x-data-grid";
// Assets/Modules
import { getSubscription, setFilter } from "../../../redux/reducers/catalog";
import OverlayLoading from "../../CustomStyled/OverlayLoading";
import { SectionWrapper } from "./styles";
import PaymentModal from "../../Modals/Payment";
import "react-credit-cards-2/dist/es/styles-compiled.css";

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, email } = useSelector((state) => state.auth);
  const { paymentLoading, loading, subscription } = useSelector(
    (state) => state.catalog
  );

  const [openPopup, setOpenPopup] = useState(false);
  // prettier-ignore

  useEffect(() => {
    dispatch(setFilter({ field: "status", value: "active" }));
    userId && dispatch(getSubscription({ userId }));
  }, []);

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const handleCardChange = () => {
    setOpenPopup(true);
  };

  return (
    <SectionWrapper id="payment-method">
      {paymentLoading || loading ? (
        <OverlayLoading />
      ) : (
        <Box className="ps-5">
          <Typography variant="h6" sx={{ margin: 2 }}>
            Payment Method
          </Typography>
          <Box id="PaymentForm" className="d-flex">
            <Box className="d-flex align-items-center flex-column">
              <ReactCreditCards
                // cvc={"123"}
                // focused={"name"}
                // name={""}
                expiry={
                  subscription?.expMonth
                    ? `${String(subscription.expMonth).padStart(
                        2,
                        "0"
                      )}/${String(subscription?.expYear % 100).padStart(
                        2,
                        "0"
                      )}`
                    : "00/00"
                }
                number={subscription?.last4?.padStart(16, "*")}
                issuer={subscription?.brand || ""}
                preview
              />
              <Box sx={{ m: 2 }}>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<Add />}
                  disabled={!subscription}
                  onClick={handleCardChange}
                >
                  Change Card
                </Button>
              </Box>
            </Box>
            <Box></Box>
          </Box>
          {/* MODALS */}
          <PaymentModal
            type="changeCard"
            open={openPopup}
            onClose={handleClosePopup}
            email={email}
          />
        </Box>
      )}
    </SectionWrapper>
  );
};

export default PaymentMethod;
