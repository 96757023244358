import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { isEmpty } from "lodash";
// MUI
import { Settings, Receipt, Subscriptions, Payment } from "@mui/icons-material";
// assets/modules
import Header from "../Header";
import { Div, Content } from "./style";
import config from "../../../style";
import "../../../style/index.css";
import "../../../assets/styles.css";
import "./index.css";

const { theme } = config;

function Layout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [currentOpenedTab, setCurrentOpenedTab] = useState("");

  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [linkToOpen, setLinkToOpen] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentSingleTab, setCurrentSingleTab] = useState("");

  const { currentUser: user } = useSelector((state) => state?.auth || {});

  // const isOwner = user?.email == "steven@wraithco.com";
  // const isAdmin = user?.role_id == 1 || user?.role_id == 3;
  // const isWarehouseWorker = user?.warehouse_manager_id;
  // const isWorker = user?.role_id == 5;
  // const isNotSpecialUser = user?.email != "test@sales.support.com";

  const path = window?.location?.pathname;
  const isFBMPages =
    path.includes("fbm") || path.includes("orders-fulfillment");

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname.includes("/dashboard/subscription")) {
      setCurrentOpenedTab("");
      setCurrentUrl("");
      setCurrentSingleTab("Subscription");
    } else if (pathname.includes("/dashboard/invoice")) {
      setCurrentOpenedTab("");
      setCurrentUrl("");
      setCurrentSingleTab("Invoice");
    } else if (pathname.includes("/dashboard/payment-method")) {
      setCurrentOpenedTab("payment-method");
      setCurrentUrl(pathname);
      setCurrentSingleTab("");
    } else if (["/users", "/account", "/payments"].includes(pathname)) {
      setCurrentOpenedTab("Settings");
      setCurrentUrl(pathname);
      setCurrentSingleTab("");
    } else if (
      [
        "/invoices",
        "/user-invoices",
        "/master-removal-invoices",
        "/removal-invoices",
        "/subscription-invoices",
        "/two-step-master-invoices",
        "/two-step-invoices",
        "/master-storage-invoices",
        "/storage-invoices",
        "/invoices/settings",
      ].includes(pathname)
    ) {
      setCurrentOpenedTab("Invoices");
      setCurrentUrl(pathname);
      setCurrentSingleTab("");
    }
  }, [window.location.pathname, collapsed]);

  const handleContextMenu = useCallback(
    (event, link) => {
      event.preventDefault();
      setLinkToOpen(link);
      setAnchorPoint({ x: event.pageX, y: event.pageY });
      setShow(true);
    },
    [setAnchorPoint]
  );
  const resetAndNavigate = async (link, reducers) => {
    if (window.location.pathname !== link) {
      // await dispatch({ type: 'RESET_APP', reducers });
      navigate(link);
    }
  };

  return (
    <ThemeProvider theme={theme} style={{ height: "100%" }}>
      <Div>
        <Header login={true} onClick={() => setCollapsed(!collapsed)} />
        <div className="d-flex sidebar-collapsed">
          {/* {show && (
            <ul
              className="context-menu"
              style={{
                top: anchorPoint.y,
                left: anchorPoint.x,
              }}
            >
              <li
                className="context-menu-item"
                onClick={() => {
                  window.open(linkToOpen, "_blank");
                  setShow(false);
                }}
              >
                Open Link In New Tab
              </li>
              <li
                className="context-menu-item"
                onClick={() => {
                  window.open(
                    linkToOpen,
                    "_blank",
                    "location=yes,height=650,width=1400,scrollbars=yes,status=yes"
                  );
                  setShow(false);
                }}
              >
                Open Link In New Window
              </li>
            </ul>
          )} */}
          <ProSidebar
            collapsed={collapsed}
            onMouseOver={() => setCollapsed(false)}
          >
            <Menu iconShape="square">
              <MenuItem
                onContextMenu={(event) =>
                  handleContextMenu(
                    event,
                    `${window.location.origin}/dashboard/subscription`
                  )
                }
                active={currentSingleTab === "Subscription"}
                className="no-border"
                icon={
                  <Subscriptions fontSize="small" sx={{ color: "#7620ff" }} />
                }
                onClick={() => resetAndNavigate("/dashboard/subscription", [])}
              >
                Subscriptions
              </MenuItem>
              <MenuItem
                onContextMenu={(event) =>
                  handleContextMenu(
                    event,
                    `${window.location.origin}/dashboard/invoice`
                  )
                }
                active={currentSingleTab === "Invoice"}
                className="no-border"
                icon={<Receipt fontSize="small" sx={{ color: "#7620ff" }} />}
                onClick={() => resetAndNavigate("/dashboard/invoice", [])}
              >
                Invoice
              </MenuItem>
              <SubMenu
                title="Payments"
                icon={<Payment fontSize="small" sx={{ color: "#7620ff" }} />}
                open={currentOpenedTab === "payment-method"}
                onClick={() => {
                  if (currentOpenedTab === "payment-method") {
                    setCurrentOpenedTab("");
                  } else {
                    setCurrentOpenedTab("payment-method");
                  }
                }}
              >
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/dashboard/payment-method`
                    )
                  }
                  active={currentUrl === "/dashboard/payment-method"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/dashboard/payment-method", ["workers"])
                  }
                >
                  Payment Method
                </MenuItem>
              </SubMenu>
              {/* <SubMenu
                title="Settings"
                // icon={<span className="icon-setting"></span>}
                icon={<Settings fontSize="small" sx={{ color: "#7620ff" }} />}
                open={currentOpenedTab === "Settings"}
                onClick={() => {
                  if (currentOpenedTab === "Settings") {
                    setCurrentOpenedTab("");
                  } else {
                    setCurrentOpenedTab("Settings");
                  }
                }}
              >
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(event, `${window.location.origin}/users`)
                  }
                  active={currentUrl === "/users"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/users", ["workers"])}
                >
                  Users
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/account`
                    )
                  }
                  active={currentUrl === "/account"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/account", ["account"])}
                >
                  Account
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/payments`
                    )
                  }
                  active={currentUrl === "/payments"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/payments", [
                      "payment",
                      "affiliateAccountSettings",
                    ])
                  }
                >
                  Payment
                </MenuItem>
              </SubMenu> */}

              {/* <SubMenu
                title="Invoices"
                icon={<Receipt fontSize="small" sx={{ color: "#7620ff" }} />}
                open={currentOpenedTab === "Invoices"}
                onClick={() => {
                  if (currentOpenedTab === "Invoices") {
                    setCurrentOpenedTab("");
                  } else {
                    setCurrentOpenedTab("Invoices");
                  }
                }}
              >
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/subscription-invoices`
                    )
                  }
                  active={currentUrl === "/subscription-invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/subscription-invoices", [
                      "subscriptionInvoices",
                    ])
                  }
                >
                  Subscription
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/invoices`
                    )
                  }
                  active={currentUrl === "/invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/invoices", [
                      "customerInvoices",
                      "affiliateAccountSettings",
                    ])
                  }
                >
                  Shipment [Master]
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/user-invoices`
                    )
                  }
                  active={currentUrl === "/user-invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/user-invoices", [
                      "userInvoices",
                      "affiliateAccountSettings",
                    ])
                  }
                >
                  Shipment
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/master-removal-invoices`
                    )
                  }
                  active={currentUrl === "/master-removal-invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/master-removal-invoices", [
                      "masterRemovalInvoices",
                    ])
                  }
                >
                  Removals [Master]
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/removal-invoices`
                    )
                  }
                  active={currentUrl === "/removal-invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/removal-invoices", ["removalInvoices"])
                  }
                >
                  Removals
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/two-step-master-invoices`
                    )
                  }
                  active={currentUrl === "/two-step-master-invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/two-step-master-invoices", [
                      "twoStepInvoices",
                    ])
                  }
                >
                  Two Steps [Master]
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/two-step-invoices`
                    )
                  }
                  active={currentUrl === "/two-step-invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/two-step-invoices", ["twoStepInvoices"])
                  }
                >
                  Two Steps
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/master-storage-invoices`
                    )
                  }
                  active={currentUrl === "/master-storage-invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/master-storage-invoices", [
                      "masterStorageInvoices",
                    ])
                  }
                >
                  Storage [Master]
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/storage-invoices`
                    )
                  }
                  active={currentUrl === "/storage-invoices"}
                  className="no-border"
                  onClick={() =>
                    resetAndNavigate("/storage-invoices", [
                      "userStorageInvoices",
                    ])
                  }
                >
                  Storage
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      `${window.location.origin}/invoices/settings`
                    )
                  }
                  active={currentUrl === "/invoices/settings"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/invoices/settings", [])}
                >
                  Settings
                </MenuItem>
              </SubMenu> */}
            </Menu>
          </ProSidebar>
          <Content
            //  style={{ height: "calc(100vh)" }}
            onMouseOver={() => {
              setCollapsed(true);
              setShow(false);
            }}
          >
            {isFBMPages ? (
              <div
                style={{ height: "calc(100vh - 85px)" }}
                className={collapsed ? "collapsed" : "collapsed-none"}
              >
                {props.children}
              </div>
            ) : (
              <div
                style={{ height: "calc(100vh - 85px)", marginTop: "-25px" }}
                className={collapsed ? "collapsed" : "collapsed-none"}
              >
                {props.children}
              </div>
            )}
          </Content>
        </div>
      </Div>
    </ThemeProvider>
  );
}

export default Layout;
