import { createTheme } from "@mui/material";
import { theme as customVariables } from "./theme-variables";

const theme = createTheme({
  palette: {
    primary: {
      main: customVariables.purple,
    },
  },
  variables: {
    // variables also in use for styled-components
    ...customVariables,
  },
  typography: {
    // fontFamily: "Khula, sans-serif",
  },
  components: {
    // Filled TextField
    MuiFilledInput: {
      styleOverrides: {
        // Name of the slot
        root: {
          borderRadius:
            customVariables["border-radius-filled-textField-muliline"],
          borderTopRightRadius:
            customVariables["border-radius-filled-textField-muliline"],
          borderTopLeftRadius:
            customVariables["border-radius-filled-textField-muliline"],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "0.2rem 0.6rem",
          borderRadius: customVariables["border-radius-button"],
          "&:hover": {
            color: customVariables.white,
            backgroundColor: customVariables.purple,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { borderRadius: "1rem" } },
    },
    MuiToolbar: {
      styleOverrides: { root: { "& > p": { marginBottom: "0.2rem" } } },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: { "&:hover": { color: customVariables.purple } },
      },
    },
  },
});

export default theme;
