import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import styled from "styled-components";
import { ResendCode } from "../../../redux/reducers/auth";

const Verification = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const { code, setCode, type: emailType } = props;
  const { email, error } = useSelector((state) => state.auth);

  useEffect(() => {}, []);

  const handleResetEmail = () => {
    dispatch(ResendCode({ email, type: emailType || type }));
  };
  const handleCodeInput = (value) => {
    setCode(value);
  };
  // const handleCodeChange = (e, index) => {
  //   const { value } = e.target;
  //   const pattern = /^\d*$/;
  //   const isValidChar = pattern.test(value);
  //   if (!isValidChar) return;
  //   const newCode = [...verifyObj.code];
  //   newCode[index] = value;
  //   setVerifyObj({ ...verifyObj, code: newCode });

  //   if (index < 6 - 1) {
  //     inputRefs[index + 1].current.focus();
  //   }
  // };

  return (
    <Wrapper>
      <small style={styles.pt67}>Email Verification</small>
      <ReactCodeInput
        type="number"
        className=""
        name="code"
        title="Code"
        value={code}
        onChange={handleCodeInput}
      />
      <small
        style={styles.small}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleResetEmail();
          }
        }}
        onClick={handleResetEmail}
      >
        Resend Code?
      </small>
      <HorizontalRule />
      <Link to="/">Goto Main Page</Link>
      {error && <small style={styles.textDanger}>{error}</small>}
    </Wrapper>
  );
};

export default Verification;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const HorizontalRule = styled.hr`
  width: 90%;
  height: 0.3rem;
  border-radius: 0.8rem;
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  background-color: #ebd0d0;
  margin: 1.5rem 0 1rem 0;
  backdrop-filter: blur(25px);
`;

const styles = {
  grid: {
    height: "100vh",
    maxWidth: "100%",
    flexBasis: "35%",
  },
  pt67: {
    marginTop: "64.59",
    marginBottom: "67px",
    color: "#000000",
    fontSize: "30px",
    textAlign: "center",
    display: "block",
  },
  textDanger: {
    color: "#ff0000",
  },
  small: {
    marginTop: "0.3125rem",
    fontSize: "0.675rem",
    letterSpacing: "normal",
    cursor: "pointer",
    // margin: auto,
    "&:hover": {
      color: "#7620ff",
    },
  },
};
