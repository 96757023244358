import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import theme from "../style/theme/mui-theme";

const UserPrivateRoute = (props) => {
  const { pathname } = useLocation();
  const { withLayout: Layout } = props;
  const { token } = useSelector((state) => state.auth);

  // useEffect(() => {if (token) {}}, [token, pathname]);

  return token ? (
    <ThemeProvider theme={theme}>
      <Layout>
        <Outlet />
      </Layout>
    </ThemeProvider>
  ) : (
    <Navigate to="/auth/sign-in" />
  );
};

export default UserPrivateRoute;
