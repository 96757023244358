import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Input from "../../CustomStyled/Inputs/InputSignIn";
import Button from "../../CustomStyled/Buttons/ButtonSignIn";
import {
  ChangePassword,
  setState,
  signOut,
} from "../../../redux/reducers/auth";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, error, loading, message } = useSelector((state) => state.auth);

  const [stateObj, setStateObj] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setStateObj({
      ...stateObj,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      stateObj.password &&
      stateObj.confirmPassword &&
      stateObj.password === stateObj.confirmPassword
    ) {
      await dispatch(ChangePassword({ token, password: stateObj.password }));
      dispatch(signOut());
    } else {
      dispatch(setState({ field: "error", value: "Password does not match!" }));
    }
  };

  return (
    <Wrapper>
      {loading && <OverlayLoading />}
      <MainContainer>
        <Title>Reset Password</Title>
        <form
          noValidate
          autoComplete="off"
          className="flexNullCenter flexColumn"
          onSubmit={handleSubmit}
        >
          <Input
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
            name="password"
            type="password"
            label="password"
            placeholder="Enter new password"
            style={styles.inputStyle}
            value={stateObj.password}
            onChange={handleInputChange}
            />
          <Input
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
            name="confirmPassword"
            type="password"
            label="confirm password "
            placeholder="confirm password"
            style={styles.inputStyle}
            value={stateObj.confirmPassword}
            onChange={handleInputChange}
          />
          <Button disabled={loading} type="submit" content="Reset" />
          {error || message ? (
            <small style={error ? styles.textDanger : styles.textGreen}>
              {error || message}
            </small>
          ) : (
            ""
          )}
        </form>
        {/* <small style={styles.alreadyMember}>
            Already a member{" "}
            <span
              style={styles.color}
              onClick={() => navigate("/auth/signin")}
            >
              Sign In
            </span>
          </small> */}
        {/* <Link to={"/auth/sign-in"}>Sign in </Link> */}
      </MainContainer>
    </Wrapper>
  );
};

export default ResetPassword;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  @media only screen and (max-width: 768px) {
    height: 96vh;
  }
  @media only screen and (min-width: 2560px) {
    height: 90vh;
  }
`;
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 55vh;
  // -webkit-backdrop-filter: blur(8.5px);
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  & > * {
    margin: 0.625rem 0;
  }
  @media only screen and (max-width: 320px) {
    height: 90vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    height: 90vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 425px) {
    height: 90vh;
    // font-size: 12px;
  }

  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    width: 60%;
    height: 80vh;
    border: 3px solid #755abe;
  }
  @media only screen and (min-width: 1280px) {
    width: 50%;
    height: 60vh;
    border: 3px solid #755abe;
  }
  @media only screen and (min-width: 2560px) {
    width: 30vw;
    height: 40vh;
    border: 3px solid #755abe;
  }
`;
const Title = styled.h1`
  padding: 10px 0px 0px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  text-align: center;
  color: #292589;
  @media only screen and (max-width: 425px) {
    font-size: 1.5rem;
  }
`;

const styles = {
  color: {
    color: "#0060df",
    cursor: "pointer",
  },
  textDanger: {
    color: "#ff0000",
    marginTop: "0.5rem",
  },
  textGreen: {
    color: "#008000",
    marginTop: "0.5rem",
  },
  alreadyMember: {
    marginTop: "26px",
    color: "#201F1E",
    textAlign: "center",
    fontSize: 14,
    display: "block",
  },
  inputStyle: {
    margin: "0 4rem 1.5rem",
    padding: "0.5rem",
  },
};
