import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
// import DateFnsUtils from "@date-io/date-fns";
// MUI
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// Assets/Modules
import { getInvoices } from "../../../redux/reducers/catalog";
import OverlayLoading from "../../CustomStyled/OverlayLoading";
import { toCommas } from "../../../utils";
import { BUSINESS_DETAIL } from "../../../utils/constants";
import "./preview.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
    backgroundColor: "#f2f2f2",
    borderRadius: "10px 10px 0px 0px",
  },
}));

const InvoiceDetails = () => {
  const classes = useStyles();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("profile"));
  const { id } = useParams();
  const { userId, email } = useSelector((state) => state.auth);
  const { invoices, paymentLoading, loading } = useSelector(
    (state) => state.catalog
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [rates, setRates] = useState(0);
  // const [vat, setVat] = useState(0);
  // const [currency, setCurrency] = useState("");
  // const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [client, setClient] = useState([]);
  const [type, setType] = React.useState("");
  // const [status, setStatus] = useState("");
  const [company, setCompany] = useState({});
  // const [sendStatus, setSendStatus] = useState(null);
  // const [downloadStatus, setDownloadStatus] = useState(null);
  const [invoiceData, setInvoiceData] = useState({
    // productName: "Basic",
    quantity: 1,
    // total: 0,
    // notes: user?.userProfile?.paymentDetails,
    // rates: "",
    // vat: 0,
    // currency: "",
    // invoiceNumber: Math.floor(Math.random() * 100000),
    // status: "",
    // type: "Invoice",
    // creator: "",
    discount: 0,
    // amountPaid: 0,
  });

  // console.log("🚀 ~ file: preview.jsx:84 ~ invoiceData:", invoiceData);

  useEffect(() => {
    if (id && invoices) {
      const arr = invoices.find((item) => item.id == id);
      setInvoiceData({ ...invoiceData, ...arr });
    } else if (id && !invoices) {
      dispatch(getInvoices({ id }));
    }
    setCompany({
      ...company,
      logo: BUSINESS_DETAIL.logo,
      name: BUSINESS_DETAIL.name,
    });
  }, [id, invoices]);

  //Get the total amount paid
  let totalAmountReceived = 0;
  for (var i = 0; i < invoices?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(invoices?.paymentRecords[i]?.amountPaid);
  }
  const totalAmount =
    invoiceData.quantity * (invoiceData.amountPaid / 100) -
    (invoiceData.quantity *
      (invoiceData.amountPaid / 100) *
      invoiceData.discount) /
      100;

  const editInvoice = (id) => {
    // history.push(`/edit/invoice/${id}`);
  };

  // const createAndDownloadPdf = () => {
  //   setDownloadStatus("loading");
  //   axios
  //     .post(`${process.env.REACT_APP_API}/create-pdf`, {
  //       name: invoices.client.name,
  //       address: invoices.client.address,
  //       phone: invoices.client.phone,
  //       email: invoices.client.email,
  //       dueDate: invoices.dueDate,
  //       date: invoices.paidAt,
  //       id: invoices.invoicesNumber,
  //       notes: invoices.notes,
  //       // subTotal: toCommas(invoices.subTotal),
  //       // total: toCommas(invoices.total),
  //       type: invoices.type,
  //       vat: invoices.vat,
  //       items: invoices.items,
  //       status: invoices.status,
  //       // totalAmountReceived: toCommas(totalAmountReceived),
  //       // balanceDue: toCommas(total - totalAmountReceived),
  //       company: company,
  //     })
  //     .then(() =>
  //       axios.get(`${process.env.REACT_APP_API}/fetch-pdf`, {
  //         responseType: "blob",
  //       })
  //     )
  //     .then((res) => {
  //       const pdfBlob = new Blob([res.data], { type: "application/pdf" });

  //       saveAs(pdfBlob, "invoice.pdf");
  //     })
  //     .then(() => setDownloadStatus("success"));
  // };

  //SEND PDF INVOICE VIA EMAIL
  // const sendPdf = (e) => {
  //   e.preventDefault();
  //   setSendStatus("loading");
  //   axios
  //     .post(`${process.env.REACT_APP_API}/send-pdf`, {
  //       name: invoices.client.name,
  //       address: invoices.client.address,
  //       phone: invoices.client.phone,
  //       email: invoices.client.email,
  //       dueDate: invoices.dueDate,
  //       date: invoices.paidAt,
  //       id: invoices.invoicesNumber,
  //       notes: invoices.notes,
  //       // subTotal: toCommas(invoices.subTotal),
  //       // total: toCommas(invoices.total),
  //       type: invoices.type,
  //       vat: invoices.vat,
  //       items: invoices.items,
  //       status: invoices.status,
  //       // totalAmountReceived: toCommas(totalAmountReceived),
  //       // balanceDue: toCommas(total - totalAmountReceived),
  //       link: `${process.env.REACT_APP_URL}/invoice/${invoices._id}`,
  //       company: company,
  //     })
  //     // .then(() => console.log("invoice sent successfully"))
  //     .then(() => setSendStatus("success"))
  //     .catch((error) => {
  //       console.log(error);
  //       setSendStatus("error");
  //     });
  // };

  const iconSize = {
    height: "18px",
    width: "18px",
    marginRight: "10px",
    color: "gray",
  };
  // const [open, setOpen] = useState(false);

  const checkStatus = () => {
    const { status } = invoiceData;
    return status === "open"
      ? "yellow"
      : status === "paid"
      ? "green"
      : status === "Unpaid"
      ? "red"
      : "red";
  };

  // if (!invoice) {
  //   return <Spinner />;
  // }

  return (
    <>
      {(paymentLoading || loading) && <OverlayLoading />}
      {invoices ? (
        <Box className="pageLayout">
          {/* <div className="buttons">
            <Button
              onClick={sendPdf}
              state={sendStatus}
              // onSuccess={() => openSnackbar("Invoice sent successfully")}
            >
              Send to Customer
            </Button>

            <Button onClick={createAndDownloadPdf} state={downloadStatus}>
              Download PDF
            </Button>

            <button
              className="btn"
              onClick={() => editInvoice(invoiceData._id)}
            >
              <BorderColor style={iconSize} />
              Edit Invoice
            </button>

            <button
              // disabled={status === 'Paid' ? true : false}
              className="btn"
              onClick={() => setOpen((prev) => !prev)}
            >
              <MonetizationOn style={iconSize} />
              Record Payment
            </button>
          </div> */}
          <div className="invoiceLayout">
            <Container className={classes.headerContainer}>
              <Grid
                container
                justifyContent="space-between"
                style={{ padding: "30px 0px" }}
              >
                <Grid
                  item
                  // onClick={() => history.push("/settings")}
                  style={{ cursor: "pointer" }}
                  display="flex"
                  alignItems="center"
                >
                  {company?.logo ? (
                    <img src={company?.logo} alt="Logo" className="logo" />
                  ) : (
                    <h2>{company?.name}</h2>
                  )}
                </Grid>
                <Grid item style={{ marginRight: 40, textAlign: "right" }}>
                  <Typography
                    style={{
                      lineSpacing: 1,
                      fontSize: 45,
                      fontWeight: 700,
                      color: "gray",
                    }}
                  >
                    Invoice
                  </Typography>
                  <Typography variant="overline" style={{ color: "gray" }}>
                    No:
                  </Typography>
                  <Typography variant="body2">
                    {invoiceData?.invoiceNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            <Divider />
            <Container>
              <Grid
                container
                justifyContent="space-between"
                style={{ marginTop: "40px" }}
              >
                <Grid item>
                  <Container style={{ marginBottom: "20px" }}>
                    <Typography
                      variant="overline"
                      style={{ color: "gray" }}
                      gutterBottom
                    >
                      From
                    </Typography>
                    <Typography variant="subtitle2">
                      {BUSINESS_DETAIL.businessName}
                    </Typography>
                    <Typography variant="body2">
                      {BUSINESS_DETAIL.email}
                    </Typography>
                    <Typography variant="body2">
                      {BUSINESS_DETAIL.phoneNumber}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {BUSINESS_DETAIL.address}
                    </Typography>
                  </Container>
                  <Container>
                    <Typography
                      variant="overline"
                      style={{ color: "gray", paddingRight: "3px" }}
                      gutterBottom
                    >
                      Invoice to
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {email}
                    </Typography>
                    <Typography variant="body2">{client?.email}</Typography>
                    <Typography variant="body2">{client?.phone}</Typography>
                    <Typography variant="body2">{client?.address}</Typography>
                  </Container>
                </Grid>

                <Grid item style={{ marginRight: 20, textAlign: "right" }}>
                  <Typography
                    variant="overline"
                    style={{ color: "gray" }}
                    gutterBottom
                  >
                    Status
                  </Typography>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ color: checkStatus() }}
                  >
                    {invoiceData?.status === "open"
                      ? "Unpaid"
                      : invoiceData.status}
                  </Typography>
                  <Typography
                    variant="overline"
                    style={{ color: "gray" }}
                    gutterBottom
                  >
                    Date
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {moment(invoiceData?.paidAt).format("MMM Do YYYY")}
                  </Typography>
                  <Typography variant="overline" gutterBottom>
                    Amount
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {"$"} {toCommas(invoiceData.amountPaid / 100)}
                  </Typography>
                </Grid>
              </Grid>
            </Container>

            <form>
              <div>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Subscription</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Price($)</TableCell>
                        <TableCell>Disc(%)</TableCell>
                        <TableCell>Amount($)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell scope="row" style={{ width: "40%" }}>
                          <InputBase
                            style={{ width: "100%" }}
                            outline="none"
                            sx={{ ml: 1, flex: 1 }}
                            type="text"
                            name="productName"
                            value={invoiceData?.productName || "Basic"}
                            placeholder="Product Name"
                            readOnly
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="quantity"
                            value={invoiceData?.quantity || 0}
                            placeholder="0"
                            readOnly
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="amountPaid"
                            value={invoiceData?.amountPaid / 100 || 0}
                            placeholder="0"
                            readOnly
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="discount"
                            value={invoiceData?.discount || 0}
                            readOnly
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="amount"
                            value={totalAmount || 0}
                            readOnly
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <div className="addButton"></div> */}
              </div>

              {/* <div className="invoiceSummary">
                <div className="summary">Invoice Summary</div>
                <div className="summaryItem">
                  <p>Subtotal:</p>
                  <h4>{subTotal}</h4>
                </div>
                <div className="summaryItem">
                  <p>{`VAT(${rates}%):`}</p>
                  <h4>{vat}</h4>
                </div>
                <div className="summaryItem">
                  <p>Total</p>
                  <h4>{currency} {toCommas(total)}</h4>
                </div>
                <div className="summaryItem">
                  <p>Paid</p>
                  <h4>{currency} {toCommas(totalAmountReceived)}</h4>
                </div>

                <div className="summaryItem">
                  <p>Balance</p>
                  <h4
                    style={{
                      color: "black",
                      fontSize: "18px",
                      lineHeight: "8px",
                    }}
                  >
                    {currency} {toCommas(total - totalAmountReceived)}
                  </h4>
                </div>
              </div> */}

              {/* <div className="note">
                <h4 style={{ marginLeft: "-10px" }}>Note/Payment Info</h4>
                <p style={{ fontSize: "14px" }}>{invoiceData.notes}</p>
              </div> */}

              {/* <button className="submitButton" type="submit">Save and continue</button> */}
            </form>
          </div>
        </Box>
      ) : null}
    </>
  );
};

export default InvoiceDetails;
