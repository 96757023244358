import styled from "styled-components";
// Components
import FullButton from "../CustomStyled/Buttons/FullButton";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";
import CrossIcon from "../../assets/svg/CrossIcon.svg";
import WarningIcon from "../../assets/svg/WarningIcon.svg";

const PricingTable = (props) => {
  const {
    icon,
    price,
    taxPrice,
    title,
    text,
    offers,
    action,
    btnTitle,
    cancelBtnTitle,
    disabled,
    cancelAtPeriodEnd,
  } = props;
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <Wrapper className="whiteBg br-16 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font30 extraBold">{price}</p>
      </div>
      <div>
        <p className="flexEndNull font15 red">
          <em>{taxPrice}</em>
        </p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div
                className="flexNullCenter"
                style={{ margin: "15px 0" }}
                key={index}
              >
                <div
                  style={{
                    position: "relative",
                    top: "-1px",
                    marginRight: "15px",
                  }}
                >
                  {item.type == "tick" && (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  )}
                  {item.type == "cross" && (
                    <div style={{ minWidth: "15px" }}>
                      <img src={CrossIcon} alt="cross-icon" />
                    </div>
                  )}
                  {item.type == "warning" && (
                    <div style={{ minWidth: "20px" }}>
                      <img src={WarningIcon} alt="warning-icon" />
                    </div>
                  )}
                </div>
                <p className="font20 extraBold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <div className="row">
        <div style={{ maxWidth: "120px", margin: "auto auto 0px" }}>
          <FullButton
            title={btnTitle}
            action={() => action("buy")}
            disabled={disabled}
          />
        </div>
        {disabled && (
          <div style={{ maxWidth: "120px", margin: "auto auto 0px" }}>
            <FullButton
              title={cancelBtnTitle}
              action={action}
              disabled={cancelAtPeriodEnd}
            />
          </div>
        )}
        {disabled && cancelAtPeriodEnd && (
          <NoteDiv>
            Note: Subscription will be cancelled automatically after
            subscription period ends.
          </NoteDiv>
        )}
      </div>
    </Wrapper>
  );
};

export default PricingTable;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  div:nth-child(4) {
    button {
      padding: 15px 30px;
    }
  }
  height: 100%;
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const NoteDiv = styled.div`
  color: red;
  text-align: center;
  margin-top: 1rem;
`;
