import styled from "styled-components";

const Input = ({ label, value, ...rest }) => {
  return (
    <Container>
      <div className="input-outline">
        <input type="text" {...rest} value={value} />
        <span className="border" />
        <label>{label}</label>
      </div>
    </Container>
  );
};

export default Input;

const Container = styled.div`
  .input-outline {
    width: 150%;
    position: relative;
    display: flex;
    @media (max-width: 425px) {
      // text-align: center;
    }

    input {
      font-size: 14px;
      padding: 18px 12px 13.5px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 8px;
      color: rgb(116, 132, 143);
      -webkit-text-fill-color: rgb(116, 132, 143);
      &:focus {
        outline: none;
      }
      &:focus ~ label,
      &:not([value=""]) ~ label {
        top: -7px;
        font-size: 14px;
        color: #6f5a9a;
        background: #fff;
        border-radius: 10px;
        padding: 0px 5px;
        @media (max-width: 320px) {
          left: 0px;
          font-size: 65%;
        }
      }
      &:hover ~ .border {
        border: 1px solid #6f5a9a !important;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        pointer-events: none;
        transition: all 0.1s ease;
      }
      &:focus ~ .border {
        border: 2px solid #6f5a9a !important;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        pointer-events: none;
        transition: all 0.1s ease;
      }
    }

    label {
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 12px;
      top: 14px;
      transition: 0.2s ease;
    }

    .border {
      border: 1px solid rgb(116, 132, 143);
      border-radius: 8px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      pointer-events: none;
      transition: all 0.3s ease;
    }
  }
`;
