import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
// Modules/Components
import { saveFeedback } from "../../../redux/reducers/catalog";
import { trimStrings } from "../../../utils";
// Assets
import ContactImg1 from "../../../assets/img/amazon-icon.jpg";
import ContactImg2 from "../../../assets/img/walmart-icon.jpg";
import ContactImg3 from "../../../assets/img/upc-lookup-icon.jpg";

const Contact = () => {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.catalog);
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const feedbackObj = {
      ...data,
      subscriptionId: subscription?.id,
    };
    const trimmedData = trimStrings(feedbackObj);
    dispatch(saveFeedback(trimmedData)).then(() => {
      setData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    });
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let&apos;s get in touch</h1>
            <p className="font13">
              Facing any issue? Communication is the key &#x2764;&#xfe0f;,
              Let&apos;s talk.
              <br />
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form id="form" onSubmit={handleOnSubmit}>
                <label className="font13">First name:</label>
                <input
                  type="text"
                  id="fname"
                  name="name"
                  value={data.name}
                  onChange={handleOnChange}
                />
                <label className="font13">Email:</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={data.email}
                  onChange={handleOnChange}
                />
                <label className="font13">Subject:</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={data.subject}
                  onChange={handleOnChange}
                />
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="message"
                  name="message"
                  value={data.message}
                  onChange={handleOnChange}
                />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput
                  type="submit"
                  form="form"
                  value="Send Message"
                  className="pointer animate br-16"
                  style={{ maxWidth: "220px" }}
                />
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div
                style={{ width: "50%" }}
                className="flexNullCenter flexColumn"
              >
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="br-10" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="br-10" />
                </ContactImgBox>
              </div>
              <div style={{ marginTop: "15%" }}>
                <ContactImgBox>
                  <img src={ContactImg3} alt="office" className="br-10 w-270" />
                </ContactImgBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Contact;

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    color: black;
    -webkit-text-fill-color: black;
    width: 100%;
    background-color: transparent !important;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 100%;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
  img {
    width: 100%;
  }
  @media (max-width: 425px) {
    max-width: 85%;
    align-self: flex-start;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
