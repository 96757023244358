import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-scroll";
// MUI
import {} from "@mui/material";
// Components
import Sidebar from "./Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { signOut } from "../../redux/reducers/auth";
import Button from "../CustomStyled/Buttons/Button";
import { BUSINESS_DETAIL } from "../../utils/constants";

const TopNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const { productList } = useSelector((state) => state.catalog);
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    const anchor = document.querySelector("#navigate-pointer");
    y < 300
      ? (anchor.style.display = "none")
      : (anchor.style.display = "inline-flex");
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const handleOnClick = () => {
    dispatch(signOut());
    window.location.href = "/";
  };

  return (
    <>
      <Sidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        token={token}
        signOut={handleOnClick}
      />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth>
            <img src={BUSINESS_DETAIL.logo} alt="ecomsource.ai" width="170px" />
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                to="home"
                spy
                smooth
                offset={-80}
                style={{ padding: "0 7px" }}
                onClick={() => navigate("/#home")}
              >
                Home
              </Link>
            </li>
            {productList?.length > 0 && (
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  to="upc-table"
                  spy
                  smooth
                  offset={-80}
                  style={{ padding: "0 7px" }}
                  onClick={() => navigate("/#upc-table")}
                >
                  Table
                </Link>
              </li>
            )}
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                to="product-data"
                spy
                smooth
                offset={-80}
                style={{ padding: "0 7px" }}
                onClick={() => navigate("/#product-data")}
              >
                Features
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                to="pricing"
                spy
                smooth
                offset={-80}
                style={{ padding: "0 7px" }}
                onClick={() => navigate("/#pricing")}
              >
                Pricing
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                to="contact"
                spy
                smooth
                offset={-80}
                style={{ padding: "0 7px" }}
                onClick={() => navigate("/#contact")}
              >
                Contact
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                to="privacy-policy"
                spy
                smooth
                offset={-80}
                style={{ padding: "0 7px" }}
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                to="tos"
                spy
                smooth
                offset={-80}
                style={{ padding: "0 7px" }}
                onClick={() => navigate("/terms-of-service")}
              >
                Terms of Service
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold pointer">
              <Button
                // $primary
                className="br-16 noWrap"
                // fontSize="01rem"
                m="0"
                onClick={() =>
                  window.open(
                    "https://chrome.google.com/webstore/detail/upc-lookup/pglhhlcbcbmfebnagjgmbchpmgokbmpb"
                  )
                }
              >
                UPC Lookup Ext.
              </Button>
            </li>
            {!token ? (
              <>
                <li className="semiBold font15 pointer px-10">
                  <button
                    type="button"
                    className="semiBold custom-link"
                    style={{ textWrap: "nowrap" }}
                    onClick={() => navigate("/auth/sign-in")}
                  >
                    Log in
                  </button>
                </li>
                <li className="semiBold font15 pointer flexCenter">
                  <button
                    type="button"
                    className="semiBold br-16 lightBg cursor-link"
                    style={{ padding: "10px 15px", textWrap: "nowrap" }}
                    onClick={() => navigate("/auth/sign-up")}
                  >
                    Get Started
                  </button>
                </li>
              </>
            ) : (
              <>
                <Button
                  // $primary
                  className="br-16"
                  onClick={() => navigate("/dashboard/subscription")}
                >
                  Dashboard
                </Button>
                <li className="semiBold font15 pointer flexCenter">
                  <SignOutButton
                    type="button"
                    className="semiBold font15 pointer br-16 lightBg"
                    onClick={handleOnClick}
                  >
                    Sign Out
                  </SignOutButton>
                </li>
              </>
            )}
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
};

export default TopNavbar;

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
  margin: 0 8%;
  @media only screen and (max-width: 960px) {
    margin: 0;
  }
  @media only screen and (max-width: 768px) {
    margin: 0 50px;
  }
  @media only screen and (max-width: 525px) {
    margin: 0;
  }
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  margin-bottom: 0;
  padding-left: 0.5rem;
  & > li {
    text-wrap: nowrap;
    & > a {
      @media (max-width: 1024px) {
        font-size: 0.738rem;
      }
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  margin-bottom: 0;
  padding-left: 1rem;
  li:first-child a {
    padding: 10px 18px 10px 0;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const SignOutButton = styled.button`
  padding: 0.625rem;
  text-wrap: nowrap;
`;
