import styled from "styled-components";

const Button = (props) => {
  const { children } = props;
  return <ButtonWrapper {...props}>{children}</ButtonWrapper>;
};

export default Button;

const ButtonWrapper = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) => (props.$primary ? "#BF4F74" : "white")};
  color: ${(props) => (props.$primary ? "white" : "#9f62ff")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  margin: ${(props) => (props.m ? props.m : "1rem")};
  padding: 0.425rem 1rem;
  border: 2px solid #9f62ff;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: #7620ff;
    border: 2px solid #7620ff;
  }
`;

// const ButtonWrapper = styled.button`
//   display: -webkit-inline-box;
//   display: -webkit-inline-flex;
//   display: -ms-inline-flexbox;
//   display: inline-flex;
//   -webkit-align-items: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   position: relative;
//   box-sizing: border-box;
//   -webkit-tap-highlight-color: transparent;
//   background-color: transparent;
//   outline: 0;
//   margin: 0;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   vertical-align: middle;
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   -webkit-text-decoration: none;
//   text-decoration: none;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 500;
//   font-size: 0.875rem;
//   line-height: 1.75;
//   letter-spacing: 0.02857em;
//   text-transform: uppercase;
//   min-width: 64px;
//   padding: 5px 15px;
//   border-radius: 4px;
//   transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
//     box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
//     border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
//     color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//   // border: 1px solid rgba(25, 118, 210, 0.5);
//   // color: #1976d2;
//   border: 1px solid rgba(118, 32, 255, 1);
//   color: #7620ff;
// `;
