import { Routes, Route } from "react-router-dom";
import SignIn from "../components/pages/Auth/SignIn";
import SignUp from "../components/pages/Auth/SignUp";
import PageNotFound from "../components/pages/PageNotFound";
import ForgetPassword from "../components/pages/Auth/ForgetPassword";

const AuthRoute = () => {
  return (
    <Routes>
      <Route path="*">
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-in" element={<SignIn />} />
        {/* <Route path="code-verify" element={<Verification />} /> */}
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default AuthRoute;
