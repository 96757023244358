import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
// MUI
import { FormGroup } from "@mui/material";
// modules/styledComponents
import { CodeVerify, signIn } from "../../../redux/reducers/auth";
import Input from "../../CustomStyled/Inputs/InputSignIn";
import Button from "../../CustomStyled/Buttons/ButtonSignIn";
import OverlayLoading from "../../CustomStyled/OverlayLoading";
import Verification from "./Verification";
// assets
import ContactImg1 from "../../../assets/img/amazon-icon.jpg";
import ContactImg2 from "../../../assets/img/walmart-icon.jpg";
import ContactImg3 from "../../../assets/img/upc-lookup-icon.jpg";
import WallPaper from "../../../assets/img/background.jpeg";

const SignIn = () => {
  // const FacebookBackground = "linear-gradient(to right, #0546A0 0%, #0546A0 40%, #663FB6 100%)";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const type = searchParams.get("type");
  const { loading, email, isActive } = useSelector((state) => state.auth);

  const [code, setCode] = useState("");
  const [signInObj, setSignInObj] = useState({
    email: "",
    password: "",
    type: "",
  });

  useEffect(() => {
    if (redirect == "buy") {
      toast.info("Must Login to buy Plan/Subscription");
    }
    code.length === 6 && codeVerify();
  }, [redirect, code]); // eslint-disable-line react-hooks/exhaustive-deps

  const codeVerify = () => {
    const data = { code, email };
    dispatch(CodeVerify(data));
  };
  const handleInputChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    setSignInObj({ ...signInObj, [name]: value });
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
    const { email, password } = signInObj;
    if (!email.trim() || !password.trim()) {
      toast.error("Invalid email or password");
      return;
    }
    dispatch(signIn(signInObj));
  };

  return (
    <div className="lightBg p-80">
      {loading && <OverlayLoading />}
      <Title>Sign In Account</Title>
      <Grid className="row mx-0">
        {isActive !== false && type !== "forget-password" ? (
          <GridItem className="col-xs-12 col-sm-12 col-md-12 col-lg-6 item1">
            <Form className="" onSubmit={handleOnSubmit}>
              <MainContainer>
                <WelcomeText>Welcome</WelcomeText>
                <InputContainer>
                  <Input
                    style={{ marginBottom: "10px" }}
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={signInObj.email}
                    onChange={handleInputChange}
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={signInObj.password}
                    onChange={handleInputChange}
                  />
                </InputContainer>
                <ButtonContainer>
                  <Button content="Sign In" />
                </ButtonContainer>
                <LoginWith>
                  <Link to={`/auth/sign-up?redirect=${redirect}`}>
                    OR SIGN UP
                  </Link>
                </LoginWith>
                <HorizontalRule />
                <Link to="/">Goto Main Page</Link>
                <ForgotPasswordHeader
                  onClick={() => navigate("/auth/forget-password")}
                >
                  Forgot Password ?
                </ForgotPasswordHeader>
              </MainContainer>
            </Form>
          </GridItem>
        ) : (
          <GridItem className="col-xs-12 col-sm-12 col-md-12 col-lg-6 item1">
            <MainContainer>
              <Verification code={code} setCode={setCode} type={type} />
            </MainContainer>
          </GridItem>
        )}
        <GridItem className="col-xs-12 col-sm-12 col-md-12 col-lg-6 flexLeftCenter hide-on-mobile item2">
          <div className="flexNullCenter flexColumn">
            <ContactImgBox>
              <img src={ContactImg1} alt="office" className="br-10 w-270" />
            </ContactImgBox>
            <ContactImgBox>
              <img src={ContactImg2} alt="office" className="br-10 w-270" />
            </ContactImgBox>
          </div>
          <ContactImgBox>
            <img src={ContactImg3} alt="office" className="br-10 w-270" />
          </ContactImgBox>
        </GridItem>
      </Grid>
    </div>
  );
};

export default SignIn;

const Title = styled.h1`
  padding: 10px 0px 0px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  text-align: center;
  color: #292589;
  @media only screen and (max-width: 425px) {
    font-size: 1.5rem;
  }
`;
const Grid = styled.div`
  padding: 2%;
  height: 100%;
  width: 100%;
`;
const GridItem = styled.div`
  padding: 0px 20px;
  @media only screen and (min-width: 1440px) {
    display: flex;
    justify-content: center;
    &:nth-child(2) {
      & > div {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    &:nth-child(2) {
      margin-top: 10%;
      & > div {
        width: 100%;
      }
    }
  }
`;
const Form = styled.form`
// background-color: linear-gradient(to right, #0546A0 0%, #0546A0 40%, #663FB6 100%);
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}`;
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  @media only screen and (max-width: 320px) {
    height: 90vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    height: 90vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 425px) {
    height: 90vh;
  }

  @media only screen and (min-width: 768px) {
    height: 80vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 80vh;
    border: 3px solid #755abe;
  }
  @media only screen and (min-width: 1280px) {
    width: 40vw;
    height: 75vh;
    border: 3px solid #755abe;
  }
  @media only screen and (min-width: 2560px) {
    width: 30vw;
    height: 40vh;
    border: 3px solid #755abe;
  }
`;
const WelcomeText = styled.h2`
  margin: 1rem 0;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 65%;
  text-fill-color: #564e4e;
  input: -webkit-autofill {
    -webkit-text-fill-color: #564e4e;
  }
`;
const ButtonContainer = styled.div`
  margin: 1rem 0 2rem 0;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 60%;
  }
`;
const LoginWith = styled.h5`
  cursor: pointer;
  a {
    &:hover {
      color: #7620ff;
    }
  }
`;
const HorizontalRule = styled.hr`
  width: 90%;
  height: 0.3rem;
  border-radius: 0.8rem;
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  background-color: #ebd0d0;
  margin: 1.5rem 0 1rem 0;
  backdrop-filter: blur(25px);
`;
const ForgotPasswordHeader = styled.h4`
  cursor: pointer;
  margin: auto;
  &:hover {
    color: #7620ff;
  }
`;
const ContactImgBox = styled.div`
  max-width: 100%;
  margin: 10px 30px 10px 0;
  img {
    width: 100%;
  }
  @media (max-width: 425px) {
    img {
      width: 110% !important;
      height: auto !important;
    }
  }
`;
