import logo from "../assets/img/logo.png";

export const STRIPE_PUBLIC_KEY =
  "pk_live_WiDO9vt2L2GZxmcMX70hpvqj00i0dBdmLV";
export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
export const PAGE_SIZE = PAGE_SIZE_OPTIONS[1];
export const PAGINATION_MODEL = {
  page: 0,
  pageSize: PAGE_SIZE,
};
export const FILTER_MODEL_UNVERIFIED = {
  items: [{ field: "sku", operator: "contains", value: "" }],
};
export const PAGINATION = [
  {
    field: "page",
    value: 0,
  },
  {
    field: "pageSize",
    value: PAGE_SIZE_OPTIONS[1],
  },
];
export const BUSINESS_DETAIL = {
  businessName: "Parallel Loop",
  name: "Ecomsource",
  email: "support@ecomsource.ai",
  phoneNumber: "+13075001425",
  address: "30 N Gould St Ste R Sheridan, WY 82801",
  extension:
    "https://chrome.google.com/webstore/detail/upc-lookup/pglhhlcbcbmfebnagjgmbchpmgokbmpb",
  logo,
  // website: "ecomsource.ai",
};
